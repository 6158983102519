import React from 'react'
import { inject } from 'mobx-react'

import { DeleteButton } from '../delete'
import { EntryTag     } from './EntryTag'


@inject('workDoneStore')
export class EntryTitle extends React.Component {
  render() {
    const entry = this.props.entry
    if (entry.kind)
      return (<div style={{textAlign: 'center'}}>{entry.name}</div>)
    else {
      return (<div>
        <DeleteButton className="btn btn-danger pull-right" title="Eintrag löschen" onClick={this.props.workDoneStore.remove(entry)} />
        <EntryTag entry={entry} />
        {entry.name || 'Klicken Sie hier zur Auswahl dieses Eintrags...'}
        {(entry.pause > 0) && <div style={{paddingTop: '6px', fontSize: '85%', color: '#600'}}>Pause: {entry.pause} Minuten</div>}
      </div>)
    }
  }
}
