import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import Select from 'react-select'

import { Logo } from './logo'

import './LogoSelector.css'

class LogoOption extends React.Component {
  render() {
    const logo = this.props.getValue()?.[0]
    const {innerProps,data,...props} = this.props
    return <div className={this.props.className+' '+innerProps.className} {...innerProps}
      onMouseDown={this.handleMouseDown}
      onMouseEnter={this.handleMouseEnter}
      onMouseMove={this.handleMouseMove}
      title={logo?.name || logo?.fileName}
    >
      <Logo logo={data} />
    </div>
  }
}

const LogoValue = ({getValue,value}) => {
  const logo = getValue()?.[0]
  return <div className="Select-value" title={logo?.name || logo?.fileName} >
    <Logo logo={logo} />
  </div>
}

@inject('binariesStore')
@observer
export class LogoSelector extends React.Component {
  render() {
    return <Select {...this.props} clearable menuPortalTarget={document.body}
      className="logo-selector"
      options={toJS(this.props.binariesStore.dataList)}
      components={{
        Option: LogoOption,
        SingleValue: LogoValue,
      }}
    />
  }
}
