import { action, autorun, computed, observable } from 'mobx'

import _ from 'lodash'

import { userStore     } from 'sdc-auth-user'
import { StandardStore, Patching } from 'sdc-mobx-stores'
import { requiredParam } from 'sdc-utilities'

import { api           } from './api'
import { sheetToOption } from '.'


export class SheetsStore extends Patching(StandardStore) {

  @observable options = []

  constructor({
    monthStore = requiredParam('monthStore'),
    ...options
  }) {
    super({
      listName  : 'sheets',
      entryName : 'sheet',
      ...options,
    })
    autorun(() => {
      if (monthStore.month.num && userStore.user.id) {
        this.list({
          params   : {month : monthStore.month.num},
          callback : this.parseSheets,
        })()
      } else {
        this.clearEntries()
      }
    })
  }

  parseSheets = deferred => payload => {
    this.setEntries(deferred)(payload)
    this.parseOptions()
  }

  @action
  parseOptions = () => {
    this.options = this.dataList.map(sheetToOption)
  }

  @action
  assign = assignment => {
    this.selected.packetID = assignment.value
    this.update(this.selected,'packetID',assignment.value)
  }

  total = computed(() => _.sum(this.dataList.map(s => s.total)))

  hasSheet = packet => _(this.dataList).find(s => s.packetID === packet.id) !== undefined

  afterSelect = sheet => {
    this.packetsStore.select(sheet.packet)()
  }
  afterUpdate = sheet => {
    this.packetsStore.patch(sheet.packetID)
    this.parseOptions()
  }

}

export const makeSheetsStore = ({backend,...options}) => new SheetsStore({...options,api : api({backend})})
