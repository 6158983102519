import styled from 'styled-components'

export const Editor = styled.div`
  padding: 0.25em 4px 0;
  margin: auto -2px;
  min-height: 1.5em;
  white-space: normal;
  line-height: 1.2em;
  border: solid 1px #ddbbcf;
  &:focus {
    color: #900;
    background-color: #e5eeff;
    text-decoration: none;
  }
`
