import React from 'react'
import { inject, observer } from 'mobx-react'

import moment from 'moment'

import { EntryFieldsList } from 'sdc-cms-editing'

export const DayDetails = inject('editingStore','daysStore')(observer(({editingStore,daysStore,...props}) =>
<div className="col-md-12 sb-content-panel">
  <h4>
    Details für den freien Tag am {moment(editingStore._sending.values?.date).format('DD.MM.YYYY')}
  </h4>
  <EntryFieldsList fields={['name']} ignoreIndex dataStore={daysStore} className="sb-editing" fmt="DD.MM.YYYY HH:mm:ss" />
</div>))
