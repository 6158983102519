import React                   from 'react'
import { inject, observer    } from 'mobx-react'

import { Label               } from 'sdc-i18n-react'

import { ReloadButton        } from '../../components/buttons'
import { DataTable           } from '../../components/DataTable'

import { CreatePartnerButton } from './createButton'
import { DeletePartnerButton } from './deleteButton'


const columns = (partnersStore) => ([{
  Header: <span>
    <ReloadButton onClick={partnersStore.reload} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => <span>
      <DeletePartnerButton entry={cell.original} />
  </span>,
},{
  Header: <Label value="field.name" />,
  accessor: 'values.name',
  headerClassName: 'left',
},{
  Header: <span>
      <CreatePartnerButton />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered',
  width: 30,
  sortable: false,
  resizable: false,
  // Cell : cell => (
  //   <span>
  //     <CreateButton title="einen neuen Auftrag anlegen" onClick={contractsStore.create} />
  //   </span>
  // )
}])

export const PartnersList = inject('partnersStore')(observer(({partnersStore}) =>
<DataTable store={partnersStore}
  columns={columns(partnersStore)}
  defaultSorted={[{
    id : 'values.name',
  }]}
/>))
