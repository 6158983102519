import React from 'react'

import { Label } from 'sdc-i18n-react'

import { TimeFieldEditor } from './datetime/time'

export const DetailsTimeEdit = ({label,field,...props}) => (<tr>
  <th><Label value={label} /></th>
  <td><TimeFieldEditor field={field} {...props} /></td>
</tr>)
