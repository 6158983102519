import React from 'react'
import { inject, observer } from 'mobx-react'

import { Link, NavLink } from 'react-router-dom'

import { LoginButton, LogoutButton, UserName } from 'sdc-auth-react'
import { userStore } from 'sdc-auth-user'

import { loginURL } from '../../user/session/urls'

import { AppVersion    } from 'sdc-react-basics'
import { LanguagesMenu } from 'sdc-i18n-react'

import { uiBase } from   '../../env/constants'

import { sheetURL, maskURL }     from '../../tracking'
import { boURL      } from '../../bo'
import { crmURL     } from '../../crm'
import { userURL    } from '../../user'
import { lettersURL } from '../../letters'

import { NavItem    } from './item'

import { UpdateAvailable } from '../update'

import './NavBar.scss'


export const NavBar = inject('contractsStore')(observer(
  ({contractsStore}) =>
  <nav className="navbar navbar-expand-lg navbar-light navbar-gmcd navbar-static-top">

      <div className="navbar-header">
        <Link className="navbar-brand" to={uiBase}>
          <span><i className="fa fa-money" style={{fontSize: '150%', lineHeight: '1em'}} /></span>
          <b>ScrumBill</b>
        </Link>
      </div>

        <ul className="nav navbar-nav mr-auto">
          <NavItem showing={userStore.user.id}
            to={crmURL} icon="users" caption="customers" />
          <NavItem showing={userStore.user.id && contractsStore.selected.id}
            to={sheetURL} icon="th-list" caption="timesheet" />
          <NavItem showing={userStore.user.id} style={{color: '#090'}}
            to={maskURL} icon="clock-o" caption="entryMask" />
          <NavItem showing={userStore.user.id}
            to={lettersURL} icon="envelope" caption="letters" />
        </ul>

        <AppVersion version={process.env.REACT_APP_VERSION} />

        <ul className="nav navbar-nav navbar-right">
          <NavItem showing={userStore.user.admin} style={{color: '#c00'}}
            to={boURL} icon="users" caption="backoffice" />
          <li className="nav-item"><NavLink className="nav-link" to={userURL}><UserName /></NavLink></li>
          <LanguagesMenu nav />
          <li className="nav-item"><UpdateAvailable className="nav-link" /></li>
          <li className="nav-item"><LoginButton url={loginURL} /></li>
          <li className="nav-item"><LogoutButton /></li>
        </ul>

  </nav>
))
