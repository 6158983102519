import moment from 'moment'

export const werktage = (store,date) => {
  const von = moment(date).date(1).startOf('day')
  const bis = moment(von).add(1,'month').add(-1,'day')

  const result = []
  for (var i = 0; i < bis.date(); i++) {
    const day = moment(von).add(i,'day')
    if (day.day() !== 0 && day.day() !== 6 && !store.isHoliday(day)) result.push(day)
  }
  return result
}
