import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label         } from 'sdc-i18n-react'

import { AlertHelp     } from '../../components/AlertHelp'
import { AlertHint     } from '../../components/AlertHint'
import { PanelHeading  } from '../../components/panels/heading'

import { PacketsList   } from './list'
import { PacketDetails } from './details'


@inject('contractsStore','packetsStore')
@observer
export class PacketsPanel extends React.Component {

  render() {
    const contractID = this.props.contractsStore.selected.id
    const {creatable: details,packetsStore: store} = this.props
    const selected = store.selected.id

    return <span>
      {!contractID && <PanelHeading value="packets.heading.common" topic="packets.intro" icon="archive" />}
      {contractID && <PanelHeading value="packets.heading.project" topic="packets.intro" label={this.props.contractsStore.selected.name} icon="archive" />}
      {contractID && <PacketsList {...this.props} filter={p => p.project === contractID} />}
      {details && !contractID && <AlertHint><Label value="contract.hint.select" /></AlertHint>}
      {details && contractID && !selected && <AlertHelp><Label value="packet.help.select" /></AlertHelp>}
      {details && selected && <hr/>}
      {details && selected && <h4><Label value="packet.details.heading" label={store.selected.name} /></h4>}
      {details && selected && <PacketDetails/>}
    </span>
  }
}
