import React from 'react'
import { inject, observer } from 'mobx-react'

import { Row, Col       } from 'reactstrap'

import { Customers      } from './customers'
import { ContractsPanel } from './contracts/panel'
import { PacketsPanel   } from '../tracking/packets/panel'
import { SheetsPanel    } from '../tracking/sheets/panel'


@inject('customerStore','packetsStore')
@observer
export class CRM extends React.Component {
  render() {
    const customerID = this.props.customerStore.selected.id
    const packetID   = this.props.packetsStore.selected.id
    return (<Row>
      <Col md={3}>
        <Customers creatable />
      </Col>
      <Col md={4}>
        <ContractsPanel creatable />
      </Col>
      {customerID && <Col md={3}>
        <PacketsPanel creatable />
      </Col>}
      {packetID && <Col md={2}>
        <SheetsPanel creatable />
      </Col>}
    </Row>)
  }
}
