import React from 'react'

import Select from 'react-select'

import { selectStyles, selectTheme } from '../design/styling'

export const CustomSelect = ({clearable = false, ...props}) => <Select {...props}
  clearable={clearable}
  menuPortalTarget={document.body}
  styles={selectStyles}
  theme={selectTheme}
/>
