import React from 'react'

import { Customer       } from './single'
import { CustomersPanel } from './panel'


export const customerToOption = customer => ({
  label : customer?.name,
  value : customer?.id,
})

export const Customers = ({creatable,...props}) =>
<React.Fragment>
  <CustomersPanel {...props} creatable={creatable} />
  {creatable && <Customer />}
</React.Fragment>
