import React      from 'react'
import { Button } from 'sdc-react-basics'

import { state  } from './state'

export class DeleteButton extends React.Component {
  handleClick = e => {
    if (this.props.confirm) {
      state.open(this.props.tag,this.props.onClick)
    } else {
      this.props.onClick(e)
    }
  }
  render() {
    const {style,onClick,prompt,confirm,...params} = {...this.props}
    return <Button icon="trash-o" onClick={this.handleClick} style={{color: '#900', ...style}} {...params} />
  }
}
DeleteButton.defaultProps = {
  confirm: true,
}
