import React from 'react'
import { inject, observer } from 'mobx-react'

import { dateCell } from '../../components/columns'

import { CreateButton, ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

import { DataTable } from '../../components/DataTable'


const columns = (messagesStore) => [{
  Header: <span>
    <ReloadButton title="texts.reload" onClick={messagesStore.list({})} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      <DeleteButton title="text.delete" onClick={messagesStore.remove(cell.original)}/>
    </span>
  )
},{
  Header: 'Kat.',
  accessor: 'category',
  headerClassName: 'left',
  width: 40,
},{
  Header: 'Titel',
  accessor: 'name',
  headerClassName: 'left',
},{
  Header: 'Schlüssel',
  accessor: 'key',
  headerClassName: 'left',
},{
  Header: 'angelegt',
  accessor: 'created',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
},{
  Header: 'veröff.',
  accessor: 'published',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
},{
  Header: <span>
    <CreateButton title="text.create" onClick={messagesStore.create()} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
    </span>
  )
}]

@inject('messagesStore')
@observer
export class MessageList extends React.Component {
  render() {
    return <DataTable store={this.props.messagesStore} columns={columns(this.props.messagesStore)}
      sorting={[{
        id: 'category',
      },{
        id: 'published',
        desc: true,
      },{
        id: 'name',
      }]}
    />
  }
}
