import React from 'react'
import { inject, observer } from 'mobx-react'

import { dateCell } from '../../components/columns'

import { CreateButton, ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

import { DataTable } from '../../components/DataTable'


const columns = (companiesStore) => [{
  Header: <span>
    <ReloadButton title="companies.reload" onClick={companiesStore.list({})} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      <DeleteButton title="company.delete" onClick={companiesStore.remove(cell.original)}/>
    </span>
  )
},{
  Header: 'Name',
  accessor: 'name',
  headerClassName: 'left',
  //Cell : textEdit(customerStore.update),
},{
  Header: 'Angelegt',
  accessor: 'created',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
},{
  Header: <span>
      <CreateButton title="company.create" onClick={companiesStore.create()} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered',
  width: 30,
  sortable: false,
  resizable: false,
  // Cell : cell => (
  //   <span>
  //     <CreateButton title="einen neuen Auftrag anlegen" onClick={contractsStore.create} />
  //   </span>
  // )
}]

@inject('companiesStore')
@observer
export class CompaniesList extends React.Component {
  render() {
    return <DataTable store={this.props.companiesStore} columns={columns(this.props.companiesStore)}
      sortable={false}
      defaultSorted={[{
        id : 'created',
        desc : true,
      }]}
    />
  }
}
