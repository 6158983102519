import React from 'react'

import { DateFormatter, DateCellEditor } from './datetime/date'
import { DateTimeFormatter } from './datetime/datetime'
import { TimeFormatter, TimeCellEditor } from './datetime/time'
import { TextCellEditor } from './text/text'
//import { WordCount } from '../manuscript/WordCount'

export const dateCell     = cell => <DateFormatter     date={cell.value} />
export const dateTimeCell = cell => <DateTimeFormatter date={cell.value} />
export const timeCell     = cell => <TimeFormatter     time={cell.value} />

export const pivotDate    = cell => <DateFormatter     date={cell.value && parseInt(cell.value.split(' ')[0],10)} />

export const dateEdit  = update => cell => <DateCellEditor cell={cell} update={update} />
export const timeEdit  = update => cell => <TimeCellEditor cell={cell} update={update} />
export const textEdit  = update => cell => <TextCellEditor cell={cell} update={update} />
//export const wordCount = cell => ( <WordCount    value={cell.value} /> )

