import React from 'react'
import { inject, observer } from 'mobx-react'

import { dateCell, dateTimeCell } from '../../components/columns'

import { Button } from 'sdc-react-basics'
import { CreateButton, ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

import { DataTable } from '../../components/DataTable'


const columns = (accountsStore,authStore) => [{
  Header: <span>
    <ReloadButton title="accounts.reload" onClick={accountsStore.list({})} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      {!cell.original.admin && <DeleteButton title="account.delete" onClick={accountsStore.remove(cell.original)}/>}
    </span>
  )
},{
  Header: 'ID',
  accessor: 'name',
  headerClassName: 'left',
},{
  Header: 'E-Mail',
  accessor: 'email',
  headerClassName: 'left',
},{
  Header: 'angelegt',
  accessor: 'added',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
},{
  Header: 'zuletzt online',
  accessor: 'lastSeen',
  className: 'centered',
  width: 120,
  resizable: false,
  Cell : dateTimeCell,
},{
  Header: <span>
    <CreateButton title="account.create" onClick={accountsStore.create()} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      {cell.original.name && <Button title="account.impers" icon="user" onClick={authStore.become(cell.original)} />}
    </span>
  )
}]

@inject('accountsStore','authStore')
@observer
export class AccountList extends React.Component {
  render() {
    return <DataTable store={this.props.accountsStore} columns={columns(this.props.accountsStore,this.props.authStore)} />
  }
}
