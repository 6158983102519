import React from 'react'

import { Label } from 'sdc-i18n-react'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError : false,
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError : true,
    })
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      return <div className="alert alert-danger"><i className="fa fa-frown-o"/> <Label value="alert.error" /></div>
    } else {
      return this.props.children
    }
  }
}
