import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import { dateCell } from '../../components/columns'

import { CreateButton, ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

import { DataTable } from '../../components/DataTable'


@inject('customerStore','contractsStore')
@observer
export class CustomersList extends React.Component {
  render() {
    const {customerStore,...otherProps} = this.props
    return <DataTable store={customerStore} columns={this.columns(this.props)} {...otherProps}
      sortable={false}
      defaultSorted={[{
        id : 'created',
        desc : true,
      }]}
    />
  }

  columns = ({narrow,creatable,customerStore,contractsStore}) => {
    if (narrow)
      return [nameCol,createdCol]
    else if (creatable)
      return [reloadTrash(customerStore),nameCol,createdCol,createButtons(customerStore,contractsStore)]
    else
      return [reloadTrash(customerStore),nameCol,createdCol]
  }

}

const reloadTrash = (customerStore) => ({
  Header: <span>
    <ReloadButton onClick={customerStore.reload} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      {cell.original.locked && <DeleteButton onClick={customerStore.remove(cell.original)}/>}
    </span>
  )
})
const nameCol = {
  Header: <Label value="field.name" />,
  accessor: 'name',
  headerClassName: 'left',
  //Cell : textEdit(customerStore.update),
}
const createdCol = {
  Header: <Label value="field.created" />,
  accessor: 'created',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
}

const createButtons = (customerStore,contractsStore) => ({
  Header: <span>
      <CreateButton title="customer.create" onClick={customerStore.create()} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      {!cell.original.locked && <CreateButton title="contract.create"
        onClick={contractsStore.create({
          ownerID : cell.original.ownerID,
          customerID : cell.original.id,
          customerName : cell.original.name,
        })} />}
    </span>
  )
})
