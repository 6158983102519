export const selectStyles = {
  control : provided => ({
    ...provided,
    height       : '12px',
    borderColor  : '#ddbbcf',
    padding      : 0
  }),
  input : provided => ({
    ...provided,
    height  : '1.5em',
    padding : '0',
  }),
  menuPortal : provided => ({
    ...provided,
    zIndex : 95,
  })
}

export const selectTheme = provided => ({
  ...provided,
  borderRadius : 1,
  spacing : {
    ...provided.spacing,
    baseUnit : 2,
    controlHeight: 34,
  }
})
