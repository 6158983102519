import React from 'react'
import { inject, observer } from 'mobx-react'

import { MoneyView } from '../../components/money/view'

export const Rates = inject('settingsStore','monthsStore','contractsStore')(observer(
({settingsStore,monthsStore,contractsStore,caption,factor=1.0}) => {
  if (!contractsStore.selected.rate) return null

  const {businessDays,bookedTime} = monthsStore.selected.values || {}
  const scaled = factor * contractsStore.selected.rate
  const daily = settingsStore.selected.dailyTime || 540
  const soll = businessDays * daily

  return <tr className="text-right">
    <th className="text-center">{caption}</th>
    <td><MoneyView value={scaled * soll / 60.0} /></td>
    <td><MoneyView value={scaled} /></td>
    <td className="font-weight-bold">{bookedTime > 0 && <MoneyView value={scaled * bookedTime / 60.0} />}</td>
  </tr>
}))
