import React from 'react'

import { Label } from 'sdc-i18n-react'

import { DatePicker } from './datetime'

export const DetailsDateEdit = ({label,field,fmt}) => (<tr>
  <th><Label value={label} /></th>
  <td><DatePicker field={field} fmt={fmt} /></td>
</tr>)
