import { action, autorun } from 'mobx'

import _ from 'lodash'

import { ContentApi    } from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'
import { userStore     } from 'sdc-auth-user'
import { requiredParam } from 'sdc-utilities'

const typeID = 'pbGxSFhbd8yYjETPaXbXJnBOenf4zmVA'

export class MonthsStore extends UpdatingStore {

  constructor({
    contractsStore = requiredParam('contractsStore'),
    daysStore      = requiredParam('daysStore'),
    monthStore     = requiredParam('monthStore'),
    holidaysStore  = requiredParam('holidaysStore'),
    workDoneStore  = requiredParam('workDoneStore'),
    sheetsStore    = requiredParam('sheetsStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
    })
    this.contractsStore = contractsStore
    this.daysStore      = daysStore
    this.monthStore     = monthStore
    this.holidaysStore  = holidaysStore
    this.workDoneStore  = workDoneStore
    this.sheetsStore    = sheetsStore

    autorun(() => {
      if (userStore.user.id) {
        this.reload()
      }
    })
    autorun(() => {
      if (contractsStore.selected.id && monthStore.selected) {
        this.updateCurrent()
      }
    })
  }

  @action
  updateCurrent = () => {
    const current = this.current()
    if (current) {
      current.values = {
        ...current.values,
        workingDays  : this.holidaysStore.workingDays(this.monthStore.selected).length,
        businessDays : this.daysStore.workingDays(this.holidaysStore.workingDays(this.monthStore.selected)).length,
        bookedDays   : this.workDoneStore.bookedDays(),
        bookedTime   : this.sheetsStore.total.get(),
      }
    }
    this.setSelected(current)
    if (current) {
      //this.editingStore.update(current)
    }
  }

  current = () => {
    if (this.contractsStore.selected.id && this.monthStore.selected) {
      const found = _.find(this.dataList, {
        values : {
          contract : this.contractsStore.selected.id,
          month    : this.monthStore.selected.valueOf(),
        }
      })
      if (!found) {
        this.createMonth()
      }
      return found
    } else return undefined
  }

  createMonth = () => {
    this.create({
      owner  : userStore.user.googleID,
      values : {
        created  : new Date().valueOf(),
        contract : this.contractsStore.selected.id,
        month    : this.monthStore.selected.valueOf(),
      }
    })()
  }

  reload = () => {
    this.list({
      params   : { owner: userStore.user.googleID },
      callback : this.parseMonths
    })()
  }

  parseMonths = deferred => action(payload => {
    this.dataList = payload.content
//    this.offDays = this.dataList.map(this.toOffDay)
    deferred.resolve(this.dataList)
  })

}
export const makeMonthsStore = ({backend,...options}) => new MonthsStore({...options,api:ContentApi({backend,typeID})})
