import { requiredParam } from 'sdc-utilities'

const baseUrl = 'settings'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  load : ()   => () => backend.get(baseUrl),
  save : data => () => backend.put(baseUrl,data),
})
