import React          from 'react'
import { observer   } from 'mobx-react'
import { FieldLabel } from '../fields/label'

import './heading.scss'


export const PanelHeading = observer(({icon,onClick,style = {},...props}) =>
<h4 className="panel-heading" style={style}>
  {icon && <i className={'fa fa-'+icon} onClick={onClick} />}
  <FieldLabel {...props} as={React.Fragment} />
</h4>)
