import React from 'react'
import { inject, observer } from 'mobx-react'

import { Header  } from './header'
import { Days    } from './days'
import { Hours   } from './hours'
import { Average } from './average'
import { Netto   } from './netto'
import { Brutto  } from './brutto'

export const Booked = inject('monthsStore','settingsStore')(observer(({monthsStore,settingsStore}) => {
  const values = monthsStore.selected.values || {}
  const daily = settingsStore.selected.dailyTime || 540
  const soll = values.businessDays * daily
  const daysLeft = values.businessDays - values.bookedDays
  const timeLeft = soll - values.bookedTime
  return <React.Fragment>
    <Header booked={values.bookedTime} />
    <Days  {...values} daysLeft={daysLeft} />
    <Hours {...values} daysLeft={daysLeft} soll={soll} timeLeft={timeLeft} />
    <Average {...values} />
    <Netto  bookedTime={values.bookedTime} soll={soll} />
    <Brutto bookedTime={values.bookedTime} soll={soll} />
  </React.Fragment>
}))
