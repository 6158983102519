import { action, autorun, observable, toJS } from 'mobx'
import { ContentApi    } from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'
import { userStore     } from 'sdc-auth-user'

import _ from 'lodash'

import { subscribe } from 'sdc-publish-subscribe'

import { partnerToOption } from '.'

const typeID = 'gOI6PEXknTLYLCkTlr5kuklA7rfEIh9O'

export class PartnersStore extends UpdatingStore {

  @observable partners = {}
  @observable options  = []

  constructor(options) {
    super({
      ...options,
      typeID,
    })
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
      } else {
        this.clearEntries()
      }
    })
    subscribe('postal-selected', this.postalSelected)
  }

  reload = () => {
    this.list({
      params : {
        owner : userStore.user.id,
      },
      callback : this.parsePartners,
    })()
  }

  parsePartners = deferred => action(payload => {
    this.partners = _.keyBy(payload.content, 'id')
    this.setEntries(deferred)(payload)
    this.parseOptions()
  })

  @action
  parseOptions = () => {
    this.options = toJS(this.dataList.filter(partner => !this.postalID || partner.values?.postal === this.postalID).map(partnerToOption))
  }

  postalSelected = postalID => {
    this.postalID = postalID
    this.parseOptions()
  }

  partner = id => partnerToOption(this.partners[id])

}

export const makePartnersStore = ({backend,...options}) => new PartnersStore({...options,api:ContentApi({backend,typeID})})
