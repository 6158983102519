import React                   from 'react'
import { action              } from 'mobx'
import { inject, observer    } from 'mobx-react'
import { Provider            } from 'mobx-react'

import { FieldLabel          } from '../../components/fields/label'

import { ColorSlider         } from '../../components/color'

import { DetailsTable        } from '../../components/DetailsTable'
import { DetailsTextEdit     } from '../../components/DetailsTextEdit'
import { DetailsTimeEdit     } from '../../components/DetailsTimeEdit'
import { DetailsTimeView     } from '../../components/DetailsTimeView'
import { DetailsCheckboxEdit } from '../../components/DetailsCheckboxEdit'
import { DetailsDateView     } from '../../components/DetailsDateView'

import { ContractSelector    } from '../../crm/contracts/selector'
import { contractToOption    } from '../../crm/contracts'

@inject('customerStore','contractsStore','packetsStore')
@observer
export class PacketDetails extends React.Component {
  render() {
    const store = this.props.packetsStore
    const data = {
      store,
      update : store.update,
    }
    return (<Provider {...data}>
      <DetailsTable>
        <colgroup>
          <col width="33%" />
          <col width="66%" />
        </colgroup>
        <tbody>
          <DetailsTextEdit label="field.ident" field="tag" />
          <DetailsTextEdit label="field.name" field="name" />
          <DetailsTextEdit label="field.alias" field="alias" />
          <DetailsTimeEdit label="field.budget" field="budget" />
          <DetailsTimeView label="field.booked" field="size" />
          <DetailsTimeView label="field.remaining" field="rest" />
          <DetailsTextEdit label="field.rate" field="rate" />
          <tr>
            <FieldLabel value="field.color" uid="packet" />
            <td style={{border: 'solid 1px #ddbbcf', padding: '8px', margin: '-2px'}}><ColorSlider
                color={this.props.packetsStore.selected.color || this.props.contractsStore.selected.color || this.props.customerStore.selected.color}
                onChange={this.setColor}
            /></td>
          </tr>
          <tr>
            <FieldLabel value="field.contract" />
            <td><ContractSelector value={contractToOption(this.props.contractsStore.contracts[store.selected.project])} onChange={store.assign} /></td>
          </tr>
          <DetailsCheckboxEdit label="field.locked" field="locked" />
          <DetailsDateView label="field.created" field="created" />
        </tbody>
      </DetailsTable>
    </Provider>)
  }

  setColor = action(color => {
    this.props.packetsStore.selected.color = color.hex
    this.props.packetsStore.update(this.props.packetsStore.selected)
  })

}
