import { action, autorun, computed, toJS } from 'mobx'

import moment from 'moment'
import _ from 'lodash'

import copy from 'copy-to-clipboard'

import { StandardStore, async } from 'sdc-mobx-stores'
import { userStore     } from 'sdc-auth-user'
import { requiredParam } from 'sdc-utilities'

import { api } from './api'

import trackingStore from '../store'


export class WorkDoneStore extends StandardStore {

  constructor({
    holidaysStore = requiredParam('holidaysStore'),
    daysStore     = requiredParam('daysStore'),
    monthStore    = requiredParam('monthStore'),
    sheetsStore   = requiredParam('sheetsStore'),
    packetsStore  = requiredParam('packetsStore'),
    ...options
  }) {
    super({
      listName  : 'workDone',
      entryName : 'workDone',
      ...options,
    })

    this.holidaysStore = holidaysStore
    this.daysStore     = daysStore
    this.monthStore    = monthStore
    this.sheetsStore   = sheetsStore
    this.packetsStore  = packetsStore

    autorun(() => {
      if (this.monthStore.month.num && userStore.user.id) {
        this.reload()
      } else {
        this.clearEntries()
      }
    })
  }

  totals = computed(
    () => {
      const grouped = _.groupBy(this.dataList.toJS().filter(e => e.time !== undefined), 'date')
      const summed  = _.mapValues(grouped, l => _.sumBy(l,'time'))
      const tots    = _.values(_.mapValues(summed, (v,k) => ({date: parseInt(k,10), time: v })))
      tots.push.apply(tots, this.holidaysStore.holidays)
      tots.push.apply(tots, this.daysStore.offDays)
      return tots
    }
  )

  copyAll = () => {
    copy('Ident-Nr.\tStunden\tTage\n'+this.identedSums().map(this.issueCSV).join('\n'))
  }

  copy = (item) => () => {
    copy(this.issueCSV(item))
  }

  issueCSV = (item) => `${item.ident}\t${(item.time/60.0).toFixed(2)}\t${(item.time/600.0).toFixed(3)}`.replace(/\./g,',')

  identedSums = () => {
    const idented = this.dataList.toJS().filter(e => e.ident)
    const grouped = _.chain(idented).groupBy('ident').toPairs().value()
    return _.orderBy(grouped.map(e => ({
      ident: e[0],
      time: _.sumBy(e[1],'time')
    })),'ident').filter(e => e.time >= 60)
  }

  setIdent = ident => action(e => {
    if (this.selected.id && (this.selected.ident !== ident)) {
      this.selected.ident = ident
      this.update(this.selected)
    }
  })

  clone = entry => e => {
    const {id,sheet,packet,...original} = entry
    const clone = {
      ...toJS(original),
      date:     trackingStore.state.rawDate,
      sheetID:  this.sheetsStore.selected.id,
      packetID: this.packetsStore.selected.id,
      month:    this.monthStore.month.num,
    }
    this.createWorkDone(clone)()
  }

  bookedDays = () => {
    const current = this.dataList.toJS().filter(e => e.month === this.monthStore.month.num)
    return _.chain(current).groupBy(e => moment(e.date).date()).toPairs().value().length
  }

  createWorkDone = entry => () => {
    this.create(entry)().then(workDone => {
      this.sheetsStore.patch(workDone.sheetID)
    })
  }

  reload = () => {
    this.list({params:{
      month: this.monthStore.month.num,
    }})().then(this.appendHolidays)
  }
  @action
  appendHolidays = () => {
    this.dataList.push.apply(this.dataList, this.holidaysStore.holidays)
    this.dataList.push.apply(this.dataList, this.daysStore.offDays)
  }

  assign = (workDone,sheetID) => action(update => {
    const oldSheetID = workDone.sheetID
    workDone.sheetID = update.value
    this.select(workDone)()
    async(this.api.update(workDone)).then(this.updateWorkDone(oldSheetID))
  })

  updateWorkDone = oldSheetID => action(payload => {
    this.selected.sheet = payload.workDone.sheet
    this.updateEntry(payload)
    this.sheetsStore.patch(this.selected.sheetID)
    this.sheetsStore.patch(oldSheetID)
  })

  beforeUpdate = workDone => {
    if (workDone.start && workDone.stop) {
      workDone.time = workDone.stop - workDone.start - workDone.pause
    } else {
      workDone.time = 0
    }
  }
  afterSelect = workDone => {
    trackingStore.state.date = new Date(workDone.date)
    this.sheetsStore.select(workDone.sheet)()
  }
  afterUpdate = workDone => {
    this.sheetsStore.patch(workDone.sheetID)
  }
}

export const makeWorkDoneStore = ({backend,...options}) => new WorkDoneStore({...options,api : api({backend})})
