import { observable, action, autorun } from 'mobx'

import { async }     from 'sdc-mobx-stores'
import { userStore     } from 'sdc-auth-user'

import { api } from './api'


class SettingsStore {
  @observable selected = {}

  dataName = 'settings'

  constructor(api) {
    this.api = api
    autorun(() => {
      if (userStore.user.id) {
        this.load()
      } else {
        this.clear()
      }
    })
  }

  load = () => {
    async(this.api.load()).then(this.setData)
  }
  save = data => {
    async(this.api.save(data)).then(this.setData)
  }

  @action
  setData = payload => {
    this.selected = payload[this.dataName] || {}
  }
  @action
  clear = () => {
    this.selected = {}
  }
}

export const makeSettingsStore = ({backend}) => new SettingsStore(api({backend}))
