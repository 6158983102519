import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import _ from 'lodash'

import { Label } from 'sdc-i18n-react'

import { pivotDate, dateEdit, timeCell, timeEdit, textEdit } from '../../components/columns'
import { PrintButton   } from 'sdc-react-basics'
import { ReloadButton  } from '../../components/buttons'
import { DeleteButton  } from '../../components/delete'
import { CloneButton   } from '../../components/clone'

import { SheetSelector } from '../sheets/selector'
import { sheetToOption } from '../sheets'

import { DataTable     } from '../../components/DataTable'

import { printBase     } from '../../env/constants'


const columns = (pivot,scale,monthStore,workDoneStore,contractsStore,copycat) => [{
  Header: <span>
    <ReloadButton title="workDone.reload" onClick={workDoneStore.reload} />
    {false && contractsStore.selected.id && <PrintButton title="month.print" href={printBase+contractsStore.selected.id+'/'+monthStore.month.num} target="_blank" />}
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 52*scale,
  sortable: false,
  resizable: false,
  Aggregated: () => null,
  Cell : cell => (
    <div style={{paddingTop: '0.25em', margin: 'auto'}}>
      {!copycat && <DeleteButton tag="workDone" title="workDone.delete" onClick={workDoneStore.remove(cell.original)} />}
      { copycat && workDoneStore.sheetsStore?.selected?.id && <CloneButton  tag="workDone" title="workDone.clone"  onClick={workDoneStore.clone(cell.original)} />}
    </div>
  )
},copycat ? undefined : {
  Header: <span onClick={pivot}><Label value="field.date" /></span>,
  accessor: 'date',
  className: 'centered',
  width: 80*scale,
  resizable: false,
  PivotValue: pivotDate,
  Cell : dateEdit(workDoneStore.update),
},copycat ? undefined : {
  Header: <Label value="field.packet" />,
  accessor: 'sheet.name',
  className: 'left',
  width: 300*scale,
  resizable: false,
  Aggregated: () => null,
  Cell : cell => <SheetSelector value={sheetToOption(cell.original?.sheet)} onChange={workDoneStore.assign(cell.original)} />
},{
  Header: <Label value="field.start" />,
  accessor: 'start',
  className: 'right',
  width: 50*scale,
  resizable: false,
  aggregate: vals => _.min(vals),
  Aggregated: timeCell,
  Cell : timeEdit(workDoneStore.update),
},{
  Header: <Label value="field.stop" />,
  accessor: 'stop',
  className: 'right',
  width: 50*scale,
  resizable: false,
  aggregate: vals => _.max(vals),
  Aggregated: timeCell,
  Cell : timeEdit(workDoneStore.update),
},copycat ? undefined : {
  Header: <Label value="field.break" />,
  accessor: 'pause',
  className: 'right',
  width: 50*scale,
  resizable: false,
  aggregate: vals => _.sum(vals),
  Aggregated: timeCell,
  Cell : timeEdit(workDoneStore.update),
},copycat ? undefined : {
  Header: <Label value="field.time" />,
  accessor: 'time',
  className: 'right padded',
  width: 50*scale,
  resizable: false,
  aggregate: vals => _.sum(vals),
  Aggregated: timeCell,
  Cell : timeCell,
},copycat ? undefined : {
  Header: <Label value="field.ident-nr" />,
  accessor: 'ident',
  headerClassName: 'left',
  width: 80*scale,
  resizable: false,
  Aggregated: () => null,
  Cell : textEdit(workDoneStore.update),
},{
  Header: <Label value="field.description" />,
  accessor: 'name',
  headerClassName: 'left',
  Aggregated: () => null,
  Cell : textEdit(workDoneStore.update),
}].filter(col => col)

@inject('workDoneStore','monthStore','contractsStore')
@observer
export class WorkDoneList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  pivot = (e) => {
    if (this.state.pivot === undefined || this.state.pivot.length === 0)
      this.setState({
        pivot: ['date']
      })
    else
      this.setState({
        pivot: []
      })
  }
  render() {
    return <DataTable store={this.props.workDoneStore} selectable={!this.props.noSelect}
      columns={columns(undefined,this.props.scale || 1.0,this.props.monthStore,this.props.workDoneStore,this.props.contractsStore,this.props.copycat)}
      style={{maxHeight:'80vh'}}
      sortable={false} additionalRows={5}
      defaultSorted={this.props.sorted || []}
      pivotBy={this.state.pivot}
      {...this.props}
    />
  }
}
