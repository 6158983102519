import { requiredParam } from 'sdc-utilities'

const baseUrl = 'postal'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  create : entry => () => backend.post(baseUrl,entry),
  update : entry => () => backend.put(baseUrl,entry),
  remove : entry => () => backend.delete(baseUrl+'/'+entry.id),
  select : entry => () => backend.post(baseUrl+'/'+entry.id),
  reload : ()    => () => backend.get(baseUrl+'/selected'),
  list   : ()    => () => backend.get(baseUrl),
})
