import React                from 'react'
import { inject, observer } from 'mobx-react'

import { AlertHelp        } from 'sdc-cms-help'

import { PanelHeading     } from '../../components/panels/heading'
import { DeletePrompt     } from '../../components/delete'

import { PartnersList     } from './list'
import { PartnerDetails   } from './details'


export const PartnersPanel = inject('partnersStore')(observer(({partnersStore,as: T = 'div',...props}) =>
<T {...props}>
  <PanelHeading value="partners.heading" topic="partners.intro" icon="user" />
  <PartnersList />
  {!partnersStore.selected.id && <AlertHelp topic="partners.select" />}
  <PartnerDetails />
  <DeletePrompt tag="partner" caption="partner.delete" />
</T>))
