import React from 'react'
import { inject, observer } from 'mobx-react'

import styled from 'styled-components'

import { Button } from 'sdc-react-basics'

import { MonthRenderer } from './MonthRenderer'

//import { monthAsProps } from '../data/month/selectors'
//import * as month from '../data/month/actions'

// const mapDispatchToProps = (dispatch) => {
//   return {
//     onNext: () => dispatch(month.select(+1)),
//     onPrev: () => dispatch(month.select(-1)),
//   }
// }

const MSel = styled.div`
  text-align: center;
  width: 100%;
  font-size: 150%;
  line-height: 1em;
  font-weight: 600;
  padding: 0 20%;
  margin-bottom: 8px;
  .btn {
    border: solid #ccc 1px;
    padding: 6px 4px 4px;
    margin-top: -6px;
  }
  .month {
    padding: 8px 4px 4px;
  }
`

@inject('monthStore')
@observer
export class MonthSelector extends React.Component {
  constructor(props) {
    super(props)
    this.month = props.monthStore
  }
  render() {
    return <MSel {...this.props} >
      {this.props.selectable && <Button icon="chevron-left  fa-fw" onClick={this.month.prev} className="btn pull-left" />}
      {this.props.selectable && <Button icon="chevron-right fa-fw" onClick={this.month.next} className="btn pull-right" />}
      <MonthRenderer />
    </MSel>
  }
}
