import { requiredParam } from 'sdc-utilities'

const baseUrl = 'packets'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  create : packet   => () => backend.post(baseUrl,packet),
  patch  : id       => () => backend.patch(baseUrl+'/'+id),
  remove : packet   => () => backend.delete(baseUrl+'/'+packet.id),
  update : packet   => () => backend.put(baseUrl,packet),
  list   : ()       => () => backend.get(baseUrl),
  select : packet   => () => backend.post(baseUrl+'/'+(packet ? packet.id : null)),
})
