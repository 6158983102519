import { action, observable } from 'mobx'

import { api } from './api'

import _ from 'lodash'

import { requiredParam } from 'sdc-utilities'

import { AuthenticatedStore } from '../../data/AuthenticatedStore'

import { companyToOption    } from '.'


export class CompaniesStore extends AuthenticatedStore {

  @observable companies = {}
  @observable options   = []

  constructor({
    binariesStore = requiredParam('binariesStore'),
    ...options
  }) {
    super({
      ...options,
      listName  : 'companies',
      entryName : 'company',
    })
    this.binariesStore = binariesStore
  }

  reload = () => {
    this.list({
      callback : this.parseCompanies,
    })()
  }

  parseCompanies = deferred => payload => {
    this.setEntries(deferred)(payload)
    this.afterUpdate()
  }

  @action
  mapCompanies = () => {
    this.companies = _.keyBy(this.dataList, 'id')
  }

  @action
  parseOptions = () => {
    this.options = this.dataList.map(companyToOption)
  }

  company = id => companyToOption(this.companies[id])

  @action
  assign = assignment => {
    this.selected.postalID = assignment.value
    this.update(this.selected)
  }
  @action
  assignLogo = assignment => {
    console.log('setting logo ID to '+(assignment?.id))
    this.selected.logoID = assignment?.id
    this.selected.logo = null
    this.update(this.selected)
  }
  afterUpdate = () => {
    this.mapCompanies()
    this.parseOptions()
  }

  @action
  addLogo = file => {
    this.binariesStore.addBinary(file).then(logo => {
      this.assignLogo({value: logo.id})
    })
  }
}

export const makeCompaniesStore = ({backend,...options}) => new CompaniesStore({...options,api : api({backend})})
