import React from 'react'

import { Button } from 'sdc-react-basics'
import { Label  } from 'sdc-i18n-react'

export const Header = ({copyAll}) => <tr>
  <th style={{textAlign: 'center', color: '#999'}}>
    {copyAll && <Button className="float-left" style={{color: '#9bf', cursor: 'pointer'}} icon="copy" title="issues.copy"
        onClick={copyAll}
    />}
    <Label value="stats.issues" />
  </th>
  <th style={{textAlign: 'center'}}><Label value="time.hm"    /></th>
  <th style={{textAlign: 'center'}}><Label value="time.hours" /></th>
  <th style={{textAlign: 'center'}}><Label value="time.days"  /></th>
</tr>
