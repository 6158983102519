import { autorun } from 'mobx'

import { StandardStore } from 'sdc-mobx-stores'
import { userStore     } from 'sdc-auth-user'

import { api } from './api'

export class AccountsStore extends StandardStore {

  constructor(options) {
    super({
      listName  : 'accounts',
      entryName : 'account',
      ...options,
    })
    autorun(() => {
      if (userStore.user.admin) {
        this.list({})()
      }
    })
  }

}

export const makeAccountsStore = ({backend}) => new AccountsStore({api : api({backend})})
