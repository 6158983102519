import React                   from 'react'
import { inject, observer    } from 'mobx-react'
import { Provider            } from 'mobx-react'

import { FieldLabel          } from '../../components/fields/label'

import { DetailsTable        } from '../../components/DetailsTable'
import { DetailsTextEdit     } from '../../components/DetailsTextEdit'
import { DetailsTimeView     } from '../../components/DetailsTimeView'
import { DetailsMonthView    } from '../../components/DetailsMonthView'

import { PacketSelector      } from '../packets/selector'
import { packetToOption      } from '../packets'

@inject('customerStore','contractsStore','packetsStore','sheetsStore')
@observer
export class SheetDetails extends React.Component {
  render() {
    const store = this.props.sheetsStore
    const data = {
      store,
      update : store.update,
    }
    return (<Provider {...data}>
      <DetailsTable>
        <colgroup>
          <col width="33%" />
          <col width="66%" />
        </colgroup>
        <tbody>
          <DetailsTextEdit label="field.ident" field="tag" />
          <DetailsTextEdit label="field.name" field="name" />
          <DetailsMonthView label="field.month" field="month" />
          <DetailsTimeView label="field.booked" field="total" />
          <tr>
            <FieldLabel value="field.packet" />
            <td><PacketSelector value={packetToOption(store.selected.packet,this.props.contractsStore)} onChange={store.assign} /></td>
          </tr>
        </tbody>
      </DetailsTable>
    </Provider>)
  }

}
