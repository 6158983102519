import React         from 'react'
import { observer  } from 'mobx-react'

import * as RS       from 'reactstrap'
import { Label     } from 'sdc-i18n-react'

import { state     } from './state'

export const DeletePrompt = observer(
({tag,caption}) => <RS.Modal isOpen={state.opened && state.opened[tag]} centered>
  <RS.ModalHeader>
    <Label value={caption} />
  </RS.ModalHeader>
  <RS.ModalFooter>
    <button className="btn btn-danger" onClick={state.proceed(tag)}><Label value="action.delete" /></button>
    <button className="btn"            onClick={state.cancel(tag)}><Label value="action.cancel" /></button>
  </RS.ModalFooter>
</RS.Modal>)
