import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label          } from 'sdc-i18n-react'

import { MarkdownPanel  } from 'sdc-markdown-viewer'

import { AlertHelp      } from '../components/AlertHelp'

import { AccountList    } from './accounts/AccountList'
import { AccountDetails } from './accounts/AccountDetails'

import { HolidayList    } from './holidays/list'
import { HolidayDetails } from './holidays/details'

import { MessageList    } from './messages/MessageList'
import { MessageDetails } from './messages/MessageDetails'

import { ErrorBoundary  } from '../components/error/boundary'


export const BackOffice = inject('accountsStore','holidaysStore','messagesStore')(observer(
  ({accountsStore,holidaysStore,messagesStore}) =>
<div className="row">
  <div className="col-md-4">
    <h4><Label value="holidays.heading" /></h4>
    <ErrorBoundary>
      <HolidayList />
      {!holidaysStore.selected.id && <AlertHelp>Wählen Sie einen Feiertag durch Anklicken aus, um die Details zu bearbeiten.</AlertHelp>}
      {holidaysStore.selected.id && <hr/>}
      {holidaysStore.selected.id && <h4>Details zu Feiertag '{holidaysStore.selected.values.name}'</h4>}
      {holidaysStore.selected.id && <HolidayDetails/>}
    </ErrorBoundary>
    <h4><Label value="users.heading" /></h4>
    <ErrorBoundary>
      <AccountList />
      {!accountsStore.selected.id && <AlertHelp>Wählen Sie einen Benutzer durch Anklicken aus, um die Details zu bearbeiten.</AlertHelp>}
      {accountsStore.selected.id && <hr/>}
      {accountsStore.selected.id && <h4>Details zu Benutzer '{accountsStore.selected.name}'</h4>}
      {accountsStore.selected.id && <AccountDetails/>}
    </ErrorBoundary>
  </div>
  <div className="col-md-4">
    <h4>Texte</h4>
    <ErrorBoundary>
      <MessageList />
      {!messagesStore.selected.id && <AlertHelp>Wählen Sie einen Text durch Anklicken aus, um die Details zu bearbeiten.</AlertHelp>}
      {messagesStore.selected.id && <hr/>}
      {messagesStore.selected.id && <h4>Details zu Text '{messagesStore.selected.name}'</h4>}
      {messagesStore.selected.id && <MessageDetails/>}
    </ErrorBoundary>
  </div>
  <div className="col-md-4">
    <MarkdownPanel text={messagesStore.selected.body} />
  </div>
</div>))
