import { requiredParam } from 'sdc-utilities'

const baseUrl = 'workDone'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  create : workDone  => () => backend.post(baseUrl,workDone),
  update : workDone  => () => backend.put(baseUrl,workDone),
  list   : ({month}) => () => backend.get(baseUrl+'/'+month),
  remove : workDone  => () => backend.delete(baseUrl+'/'+workDone.id),
})
