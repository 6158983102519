import React                from 'react'
import { inject, observer } from 'mobx-react'

import { AlertHelp    } from 'sdc-cms-help'

import { DeletePrompt } from '../../components/delete'

import { TextDetails  } from './details'

export const TextsPanel = inject('textsStore')(observer(({textsStore,...props}) =>
<div {...props}>
  {!textsStore.selected.id && <AlertHelp topic="messages.select" />}
  <TextDetails />
  <DeletePrompt tag="message" caption="message.delete" />
</div>))
