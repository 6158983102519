import React from 'react'

import { Label } from 'sdc-i18n-react'

import { CheckboxFieldEditor } from './check/box'

export const DetailsCheckboxEdit = ({label,field}) => (<tr>
  <th><Label value={label} /></th>
  <td><CheckboxFieldEditor field={field} /></td>
</tr>)
