import React        from 'react'
import { inject, observer } from 'mobx-react'

import './view.scss'

export const PostalView = inject('postalStore')(observer(
({postalStore,postal,className='',withDetails=false}) => {
  return postal ? <div className={'postal-view '+className} onClick={postalStore.select(postal)}>
    <div>{postal.name   || '<<Name>>'}</div>
    <div>{postal.zusatz || '<<Zusatz>>'}</div>
    <div>{postal.street || '<<Straße>>'}</div>
    <div className="city">{postal.zipCode || '<<PLZ>>'} {postal.city || '<<Ort>>'}</div>
    {withDetails && <div className="phone">{postal.fon   || '<<Fon>>'}</div>}
    {withDetails && <div className="phone">{postal.fax   || '<<Fax>>'}</div>}
    {withDetails && <div className="email">{postal.email || '<<E-Mail>>'}</div>}
  </div> : null
}))
