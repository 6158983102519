import { action, autorun, observable } from 'mobx'

import _ from 'lodash'

import { subscribe } from 'sdc-publish-subscribe'

import { AuthenticatedStore } from '../../data/AuthenticatedStore'

import { api            } from './api'
import { packetToOption } from '.'


export class PacketStore extends AuthenticatedStore {

  @observable packets = {}
  @observable options = []

  constructor({
    sheetsStore,
    monthStore,
    ...options
  }) {
    super({
      ...options,
      listName  : "packets",
      entryName : "packet",
    })
    this.sheetsStore = sheetsStore
    this.monthStore  = monthStore
    subscribe('customer-selected', this.customerSelected)
    subscribe('contract-cloned', ({oldContractID,newContractID} = {}) => {
      if (oldContractID) {
        const toClone = this.dataList.filter(p => p.project === oldContractID)
        toClone.forEach(packet => {
          const {id,size,created,locked,contract,...cloned} = {...packet, project: newContractID}
          this.create(cloned)()
        })
      }
    })
    subscribe('contract-locked', contract => {
      if (contract) {
        console.log('locking packets of contract '+ contract.name)
        this.lock(contract.id)
      }
    })
  }

  reload = () => {
    this.list({
      callback : this.parsePackets
    })()
  }

  parsePackets = deferred => action(payload => {
    this.setEntries(deferred)(payload)
    this.parseOptions()
    this.packets = _.keyBy(payload.packets, 'id')
  })

  customerSelected = customerID => {
    this.customerID = customerID
    this.parseOptions()
  }

  @action
  parseOptions = () => {
    this.options = this.dataList.filter(packet => !packet.locked && packet.contract?.customer?.id === this.customerID).map(packetToOption,this.contractsStore)
  }

  @action
  assign = assignment => {
    this.selected.project = assignment.value
    this.update(this.selected,'project',assignment.value)
  }

  clone = packet => action(e => {
    const {id,size,rest,created,...cloned} = {...packet}
    this.create(cloned)()
  })

  createMissing = () => {
    this.dataList.filter(p => !p.locked && p.project === this.contractsStore.selected.id).forEach(p => {
      if (!this.sheetsStore.hasSheet(p)) {
        console.log(p.name)
        this.sheetsStore.create({
          packetID: p.id,
          month: this.monthStore.month.num,
        })()
      }
    })
  }

  afterSelect = packet => {
    this.contractsStore.select(this.contractsStore.contracts[packet.project])()
  }
  afterUpdate = packet => {
    this.contractsStore.patch(packet.project)
    this.parseOptions()
  }
  lock = action(project => {
    this.dataList.filter(entry => entry.project === project).forEach(entry => {
      console.log('locking packet '+ entry.name)
      entry.locked = true
      this.update(entry,'locked',true)
    })
  })
}

export const makePacketsStore = ({backend,...options}) => new PacketStore({...options,api : api({backend})})
