import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label         } from 'sdc-i18n-react'

import { TimeFormatter } from '../../components/datetime/time'

export const Average = inject('settingsStore','monthsStore')(observer(({settingsStore,monthsStore}) => {
  const {businessDays,bookedDays,bookedTime} = monthsStore.selected.values || {}
  const daily = settingsStore.selected.dailyTime || 540
  const soll = businessDays * daily

  const average = Math.floor(bookedTime / bookedDays)
  const daysLeft = businessDays - bookedDays
  const timeLeft = soll - bookedTime
  const pending = Math.floor(timeLeft / daysLeft)
  const offset = daily - average
  const bgAver = (offset < 0 ? `rgb(${255+offset},255,${255+offset})` : `rgb(255,${255-offset},${255-offset})`)
  return <tr className="text-right">
    <th className="text-center"><Label value="planning.average" /></th>
    <td className="pr-3"><TimeFormatter inline time={daily} /></td>
    <td className="pr-3">{daysLeft > 0 && <TimeFormatter inline time={pending} />}</td>
    <td className="pr-3" style={{backgroundColor: bgAver}}><TimeFormatter inline time={average} /></td>
  </tr>
}))
