import { action, observable } from 'mobx'

import _ from 'lodash'

import { publish } from 'sdc-publish-subscribe'

import { AuthenticatedStore } from '../../data/AuthenticatedStore'

import { api            } from './api'
import { postalToOption } from '.'

export class PostalStore extends AuthenticatedStore {

  @observable postals = {}
  @observable options = []

  constructor(options) {
    super({
      ...options,
      listName  : 'addresses',
      entryName : 'address',
    })
  }

  reload = () => {
    this.list({
      callback : this.parsePostals,
    })()
  }

  parsePostals = deferred => action(payload => {
    this.postals = _.keyBy(payload.addresses, 'id')
    this.setEntries(deferred)(payload)
    this.parseOptions()
  })

  @action
  parseOptions = () => {
    this.options = this.dataList.map(postalToOption)
  }

  postal = id => postalToOption(this.postals[id])

  afterSelect = postal => {
    publish('postal-selected', postal.id)
  }

}

export const makePostalStore = ({backend}) => new PostalStore({api : api({backend})})
