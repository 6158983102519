import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import { urlBase } from '../../env/constants'

import { userStore   } from 'sdc-auth-user'
//import { PrintButton } from 'sdc-react-basics'

export const PrintTextButton = inject('textsStore','partnersStore')(observer(({
  authStore,textsStore,partnersStore,entry
}) => {
  const letter  = toJS(entry.values)
  letter.from   = toJS(userStore.user.name)
  letter.to     = toJS(partnersStore.partners[letter.to]?.values)

  let data = JSON.stringify(letter)
  //data = data.replace(/&/g,'%26')
  //data = data.replace(/\{/g,'%7B')
  //data = data.replace(/\}/g,'%7D')
  data = data.replace(/\\n\\n/g,'[newline]')

  return <form target="_blank" method="post" action={urlBase+'print.pdf'}>
    <input type="hidden" name="data" value={data} />
    <button type="submit" className="btn btn-default" caption="text.print" >Print</button>
  </form>
}))
