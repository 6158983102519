import React from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

import { Label } from 'sdc-i18n-react'

import { DateFormatter } from './datetime/date'

@inject('store')
@observer
export class DetailsMonthView extends React.Component {
  render() {
    return <tr style={{color: '#999'}}>
      <td><Label value={this.props.label} /></td>
      <td><DateFormatter date={moment(this.props.store.selected[this.props.field],'YYYYMM')} fmt={this.props.fmt || "MMMM YYYY"} /></td>
    </tr>
  }
}
