import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import { CloneButton, ReloadButton } from '../../components/buttons'

import { DataTable } from '../../components/DataTable'

import { CreateTextButton } from './createButton'
import { DeleteTextButton } from './deleteButton'


const columns = (textsStore) => ([{
  Header: <span>
    <ReloadButton onClick={textsStore.reload} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => <span>
      <DeleteTextButton entry={cell.original} />
  </span>,
},{
  Header: <Label value="field.betreff" />,
  accessor: 'values.name',
  headerClassName: 'left',
},{
  Header: <span>
      <CreateTextButton />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      <CloneButton onClick={textsStore.clone(cell.original)} title="text.clone" />
    </span>
  )
}])

export const TextsList = inject('postalStore','textsStore')(observer(({textsStore}) =>
<DataTable store={textsStore}
  columns={columns(textsStore)}
  defaultSorted={[{
    id : 'values.name',
  }]}
/>))
