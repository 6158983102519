import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import { timeCell } from '../../components/columns'

import { CloneButton, CreateButton, ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

import { DataTable } from '../../components/DataTable'


const columns = (creatable,monthStore,packetsStore,sheetsStore,contractsStore) => [{
  Header: <span>
    <ReloadButton title="packets.reload" onClick={packetsStore.reload} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      {!cell.original.size && !sheetsStore.hasSheet(cell.original) && <DeleteButton title="packet.delete" onClick={packetsStore.remove(cell.original)} confirm={false} />}
      {cell.original.size !== null && <CloneButton onClick={packetsStore.clone(cell.original)}/>}
    </span>
  )
},{
  Header: <Label value="field.ident" />,
  id: 'tag',
  accessor: ({tag=''}) => tag?.length > 8 ? '..'+tag.substring(tag.length-8) : tag,
  headerClassName: 'left',
  width: 80,
//  Cell : textEdit(packetsStore.update),
},{
  Header: <Label value="field.name" />,
  accessor: 'name',
  headerClassName: 'left',
//  Cell : textEdit(packetsStore.update),
},{
  Header: <Label value="field.alias" />,
  accessor: 'alias',
  headerClassName: 'left',
  width: 80,
//  Cell : textEdit(packetsStore.update),
},{
  Header: <Label value="field.time" />,
  accessor: 'size',
  className: 'right',
  width: 50,
  resizable: false,
  Cell : timeCell,
},{
  Header: <span>
    {contractsStore.selected.id && !contractsStore.selected.locked && <CreateButton title="ein neues Paket anlegen" onClick={packetsStore.create({
      project: contractsStore.selected.id,
      customer: contractsStore.selected.customer.id,
    })} />}
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: (creatable ? 30 : 0),
  sortable: false,
  resizable: false,
  show: creatable,
  Cell : cell => (creatable ?
    <span>
      {cell.original.name && !cell.original.locked && !contractsStore.selected.locked && !sheetsStore.hasSheet(cell.original) && <CreateButton title="ein neues Sheet anlegen" onClick={sheetsStore.create({
        packetID: cell.original.id,
        month: monthStore.month.num,
      })} />}
    </span>
  : null)
}]

@inject('packetsStore','monthStore','sheetsStore','contractsStore')
@observer
export class PacketsList extends React.Component {
  render() {
    const {packetsStore,monthStore,sheetsStore,contractsStore,creatable,...otherProps} = this.props
    return <DataTable store={packetsStore} columns={columns(creatable,monthStore,packetsStore,sheetsStore,contractsStore)} {...otherProps} />
  }
}
