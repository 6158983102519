import React from 'react'
import { observer } from 'mobx-react'

import { NavLink } from 'react-router-dom'

import { Label } from 'sdc-i18n-react'

import * as about from './about'

import './footer.scss'

export const Footer = observer(() =>
<footer className="footer">
  <div className="navbar navbar-expand-lg navbar-light navbar-gmcd" style={{marginBottom: '0px'}}>
    <ul className="navbar-nav mr-auto">
      <li className="nav-item"><NavLink className="nav-link" to="/about"><Label value="about.about" /></NavLink></li>
      {false && <li><a href="xxx"><Label value="about.blog" /></a></li>}
      {false && <li><a href="/careers"><Label value="about.jobs" /></a></li>}
      {false && <li className="nav-item"><NavLink className="nav-link" to="http://support.booxx.net"><Label value="about.support" /></NavLink></li>}
      <li className="nav-item"><NavLink className="nav-link" to="/terms"><Label value="about.terms" /></NavLink></li>
      <li className="nav-item"><NavLink className="nav-link" to="/privacy"><Label value="about.privacy" /></NavLink></li>
      <li className="nav-item"><NavLink className="nav-link" to={about.legalURL}><Label value="about.legal" /></NavLink></li>
    </ul>
    <span className="navbar-text">All
    pages handmade using ecologically sustainable code.<br/>No bytes were
    harmed in the making of this site.</span>
  </div>
</footer>)
