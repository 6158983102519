import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider } from 'mobx-react'

import { DetailsTable    } from '../../components/DetailsTable'
import { DetailsTextEdit } from '../../components/DetailsTextEdit'
import { DetailsDateEdit } from '../../components/DetailsDateEdit'
import { DetailsDateView } from '../../components/DetailsDateView'


@inject('messagesStore')
@observer
export class MessageDetails extends React.Component {
  render() {
    const data = {
      store : this.props.messagesStore,
      update : this.props.messagesStore.update,
    }
    return (<div><Provider {...data}><DetailsTable>
      <colgroup>
        <col width="33%" />
        <col width="66%" />
      </colgroup>
      <tbody>
        <DetailsTextEdit label="Name"      field="name" />
        <DetailsTextEdit label="Schlüssel" field="key" />
        <DetailsTextEdit label="Kategorie" field="category" />
        <DetailsDateEdit label="veröffentlicht" field="published" />
        <DetailsDateView label="angelegt"  field="created" />
      </tbody>
    </DetailsTable></Provider>
    <textarea value={this.props.messagesStore.snapshot.body}
              onChange={this.props.messagesStore.updateBody}
              cols="90" rows="10" />
    </div>)
  }
}
