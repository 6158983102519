import React from 'react'
import { observer } from 'mobx-react'

import { NavLink } from 'react-router-dom'

import { Label } from 'sdc-i18n-react'

import './item.scss'


export const NavItem = observer(({showing,to,icon,caption,style={}}) => showing ?
  <li className="nav-item">
    <NavLink className="nav-link" to={to} style={style}>
      <i className={'fa fa-'+icon} /> <Label value={'nav.'+caption} />
    </NavLink>
  </li> : null
)
