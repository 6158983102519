import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import { TimeFormatter } from './datetime/time'

@inject('store')
@observer
export class DetailsTimeView extends React.Component {
  render() {
    return <tr style={{color: '#999'}}>
      <th><Label value={this.props.label} /></th>
      <td><TimeFormatter time={this.props.store.selected[this.props.field]} /></td>
    </tr>
  }
}
