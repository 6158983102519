import React from 'react'

import { MonthSelector   } from '../components/MonthSelector'

import { PacketsList     } from './packets/list'
import { SheetsList      } from './sheets/list'
import { WorkDoneList    } from './workDone/WorkDoneList'
import { WorkDoneDetails } from './workDone/WorkDoneDetails'

export class TimeSheet extends React.Component {
  render() {
    return <div className="row">
      <div className="col-md-3">
        <PacketsList creatable filter={e => !e.locked} />
        <hr />
        <MonthSelector selectable />
        <hr />
        <SheetsList creatable />
        <hr />
        <WorkDoneDetails />
      </div>
      <div className="col-md-9">
        <WorkDoneList filter={e => !e.kind} />
      </div>
    </div>
  }
}
