import React              from 'react'
import { inject, observer } from 'mobx-react'

import { AlertHelp        } from 'sdc-cms-help'

import { PanelHeading     } from '../../components/panels/heading'

import { CompaniesList    } from './list'
import { CompanyDetails   } from './details'

import { LogoUploadPanel  } from '../../crm/binaries/LogoUploadPanel'


export const CompaniesPanel = inject('companiesStore')(observer(({companiesStore}) => <React.Fragment>
  <PanelHeading value="companies.heading" topic="companies.intro" icon="industry" />
  <CompaniesList />
  {!companiesStore.selected.id && <AlertHelp>Wählen Sie eine Firma durch Anklicken aus, um die Details zu bearbeiten.</AlertHelp>}
  {companiesStore.selected.id && <hr/>}
  {companiesStore.selected.id && <h4>Details zu Firma '{companiesStore.selected.name}'</h4>}
  {companiesStore.selected.id && <CompanyDetails/>}
  {companiesStore.selected.id && <LogoUploadPanel titel="Logo hochladen"
    onDrop={companiesStore.addLogo}
    hint="Ziehen Sie eine (neue) Logo-Datei hierher oder klicken Sie, um eine Datei zum Upload auszuwählen." />}
</React.Fragment>))
