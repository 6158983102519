import React                   from 'react'
import { action              } from 'mobx'
import { inject, observer    } from 'mobx-react'
import { Provider            } from 'mobx-react'

import { FieldLabel          } from '../../components/fields/label'

import { ColorSlider         } from '../../components/color'

import { DetailsTable        } from '../../components/DetailsTable'
import { DetailsTextEdit     } from '../../components/DetailsTextEdit'
import { DetailsCheckboxEdit } from '../../components/DetailsCheckboxEdit'
import { DetailsDateView     } from '../../components/DetailsDateView'

import { CompanySelector     } from '../companies/selector'
import { companyToOption     } from '../companies'

import { PostalSelector      } from '../postal/selector'
import { postalToOption      } from '../postal'


@inject('customerStore')
@observer
export class CustomerDetails extends React.Component {
  render() {
    const store = this.props.customerStore
    const data = {
      store : store,
      update : store.update,
    }
    return (<Provider {...data}><DetailsTable>
      <colgroup>
        <col width="33%" />
        <col width="66%" />
      </colgroup>
      <tbody>
        <DetailsTextEdit label="field.name" field="name" />
        <DetailsTextEdit label="field.alias" field="alias" />
        <tr>
          <FieldLabel value="field.color" uid="customer" />
          <td style={{border: 'solid 1px #ddbbcf', padding: '8px', margin: '-2px'}}><ColorSlider
              color={this.props.customerStore.selected.color}
              onChange={this.setColor}
          /></td>
        </tr>
        <tr>
          <FieldLabel value="field.contractor" />
          <td><CompanySelector value={companyToOption(store.selected.contractor)} onChange={store.assign} /></td>
        </tr>
        <tr>
          <th>Post-Anschrift</th>
          <td><PostalSelector value={postalToOption(store.selected.postal)} onChange={store.postal} /></td>
        </tr>
        <DetailsCheckboxEdit label="field.locked" field="locked" />
        <DetailsDateView label="field.created" field="created" />
      </tbody>
    </DetailsTable></Provider>)
  }

  setColor = action(color => {
    this.props.customerStore.selected.color = color.hex
    this.props.customerStore.update(this.props.customerStore.selected)
  })

}
