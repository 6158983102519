import React from 'react'
import { inject, observer } from 'mobx-react'

import { dateCell } from '../../components/columns'

import { ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

import { DataTable } from '../../components/DataTable'

import { Logo } from './logo'


const columns = (binariesStore) => [{
  Header: <span>
    <ReloadButton title="binaries.reload" onClick={binariesStore.list} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      <DeleteButton title="binary.delete" onClick={binariesStore.remove(cell.original)}/>
    </span>
  )
},{
  Header: 'Alias',
  accessor: 'name',
  headerClassName: 'left',
  //Cell : textEdit(customerStore.update),
},{
  Header: 'Datei-Name',
  accessor: 'fileName',
  headerClassName: 'left',
  //Cell : textEdit(customerStore.update),
},{
  Header: 'Vorschau',
  accessor: 'content',
  headerClassName: 'left',
  width: 210,
  Cell : cell => <Logo logo={cell.original} />,
},{
  Header: 'Angelegt',
  accessor: 'created',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
}]

@inject('binariesStore')
@observer
export class BinariesList extends React.Component {
  render() {
    return <DataTable store={this.props.binariesStore} columns={columns(this.props.binariesStore)}
      sortable={false}
      defaultSorted={[{
        id : 'created',
        desc : true,
      }]}
    />
  }
}
