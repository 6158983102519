import React from 'react'
import { inject, observer } from 'mobx-react'
import { action, toJS } from 'mobx'

import cn from 'classnames'
import * as RS from 'reactstrap'

import { userStore     } from 'sdc-auth-user'

import { Label         } from 'sdc-i18n-react'

import { PanelHeading  } from '../components/panels/heading'

import { Customers     } from '../crm/customers'
import { ContractsList } from '../crm/contracts/list'

import moment from 'moment';
import { fromDate, toDate, toMoment, TimeFormatter } from '../components/datetime/time'
import { Button        } from 'sdc-react-basics'
import { DeletePrompt  } from '../components/delete'

import { MonthSelector } from '../components/MonthSelector'
import   BigCalendar     from '@sdc-software/react-big-calendar'

import { EntryTitle    } from '../components/calendar/EntryTitle'

import { PacketsList   } from './packets/list'
import { SheetsList    }  from './sheets/list'
import { MissingSheets } from './sheets/MissingSheets'
import { WorkDoneList  } from './workDone/WorkDoneList'
import { WorkDoneDetails } from './workDone/WorkDoneDetails'
import { MonthlyStats } from './workDone/MonthlyStats'

import { AlertHelp } from '../components/AlertHelp'
import { AlertHint } from '../components/AlertHint'

import { InlineHelp } from '../components/help/InlineHelp'

import { shadeBlendConvert } from '../design/ColorBlender'

import { localizer } from '../app'

import '../components/calendar/Calendar.css'


@inject('customerStore','contractsStore',
  'monthStore','holidaysStore','daysStore',
  'trackingStore','packetsStore','sheetsStore','workDoneStore',
  'settingsStore')
@observer
export class EntryMask extends React.Component {
  render() {
    return <div>
    <div className="row">
      <RS.Fade in={this.props.trackingStore.state.view !== 'day'} mountOnEnter unmountOnExit className="col-md-3">
        <Customers narrow onlyActive />
        {this.props.contractsStore.dataList.length === 0 && <div><Label value="contracts.empty" /></div>}
        {this.props.contractsStore.dataList.length > 0 && <h4><Label value="contracts.heading.common" /></h4>}
        {this.props.contractsStore.dataList.length > 0 && <ContractsList narrow
            filter={c => !c.locked && !c.customer.locked} />}
        <hr />
        {!this.props.packetsStore.selected.id && <AlertHint><Label value="projects.hint.select"/></AlertHint>}
        <PanelHeading value="projects.heading" topic="packets.intro" position="left" />
        <PacketsList creatable onlyActive
            filter={p => !p.locked && !p.contract.locked && !p.contract.customer.locked}/>
      </RS.Fade>
      <div className={this.props.trackingStore.state.view === 'day' ? 'col-md-4' : 'col-md-6'}>
        <div className="clearfix">
          {this.props.sheetsStore.dataList.length > 0 && !this.props.sheetsStore.selected.id && <InlineHelp messageKey="em.entry" opened />}
        </div>
        <BigCalendar views={['month','work_week','day']} defaultView="day"
            //defaultDate={this.props.workDoneStore.selected.date}
            localizer={localizer}
            events={this.props.workDoneStore.dataList.toJS()}
            date={this.props.trackingStore.state.date}
            onNavigate={this.selectDate}
            step={parseInt(this.props.settingsStore.selected.step || '10',10)}
            timeslots={parseInt(this.props.settingsStore.selected.timeslots || '3', 10)} selectable
            startAccessor={this.getStart}
            endAccessor={this.getStop}
            titleAccessor={this.getTitle}
            dayPropGetter={this.getDayStyling}
            eventPropGetter={this.getStyling}
            slotPropGetter={this.getSlotStyling}
            onSelectSlot={this.selectSlot}
            onSelectEvent={this.selectEntry}
            onView={this.selectView}
            allDayAccessor={(event) => event.kind}
            min={toMoment(this.props.settingsStore.selected.startTime ||  360).toDate()}
            max={toMoment(this.props.settingsStore.selected.stopTime  || 1080).add(-1,'second').toDate()}
            messages={{
              allDay : 'Ganztags',
              previous : 'zurück',
              next : 'vor',
              today : 'heute',
              month : 'Monat',
              week : 'Woche',
              work_week : 'Werktage',
              day : 'Tag',
            }}
        />
      </div>
      <RS.Fade in={this.props.trackingStore.state.view !== 'day'} mountOnEnter unmountOnExit className="col-md-3">
        <MonthSelector />
        <SheetsList />
        <DeletePrompt tag="sheet" caption="sheet.delete" />
        <MonthlyStats />
        <MissingSheets />
        <hr />
        {!this.props.workDoneStore.selected.id && <AlertHelp>Wählen Sie einen Eintrag im Kalender aus, um diesen dann zu bearbeiten oder weitere Zeiten zum selben Paket zu erfassen.</AlertHelp>}
        <WorkDoneDetails />
        <hr />
        <PanelHeading value="month.overview"
            label={this.props.monthStore.current} />
        <BigCalendar views={['month']} defaultView="month" style={{height: '400px'}}
            localizer={localizer}
            date={this.props.monthStore.selected}
            onNavigate={this.selectDate}
            events={this.props.workDoneStore.totals.get()}
            toolbar={false}
            startAccessor={e => e.date}
            endAccessor={e => e.date}
            titleAccessor={this.getDailyTitle}
            dayPropGetter={this.getDayStyling}
            eventPropGetter={this.getDailyStyling}
            slotPropGetter={this.getSlotStyling}
            allDayAccessor={(event) => true}
            messages={{
              allDay : 'Ganztags',
              previous : 'zurück',
              next : 'vor',
              today : 'heute',
              month : 'Monat',
              week : 'Woche',
              work_week : 'Werktage',
            }}
        />
      </RS.Fade>
      <RS.Fade in={this.props.trackingStore.state.view === 'day'} mountOnEnter unmountOnExit className="col-md-8">
        <div className="row">
          <div className="col-md-12">
            <h2>
              <span className="pull-right">
                {!this.props.daysStore.isDone(this.props.trackingStore.state.date) &&
                <Button icon="calendar-check-o" style={{color: '#999', cursor: 'pointer', marginRight: '8px'}} title="doneDay.create"
                  onClick={this.props.daysStore.create({
                    owner: toJS(userStore.user.googleID),
                    values: {
                      name: 'erfasst',
                      mode: 'done',
                      date: this.props.trackingStore.state.date.valueOf(),
                    }
                  })} />}
                {this.props.daysStore.isDone(this.props.trackingStore.state.date) &&
                <Button icon="calendar-check-o" style={{color: '#900', cursor: 'pointer', marginRight: '8px'}} title="doneDay.delete"
                  onClick={this.props.daysStore.removeDay(this.props.trackingStore.state.date,'erfasst')} />}
                {!this.props.daysStore.isOffDay(this.props.trackingStore.state.date) &&
                <Button icon="plane" style={{color: '#999', cursor: 'pointer'}} title="offDay.create"
                  onClick={this.props.daysStore.create({
                    owner: toJS(userStore.user.googleID),
                    values: {
                      name: 'frei',
                      date: this.props.trackingStore.state.date.valueOf(),
                    }
                  })} />}
                {this.props.daysStore.isOffDay(this.props.trackingStore.state.date) &&
                <Button icon="plane" style={{color: '#090', cursor: 'pointer'}} title="offDay.delete"
                  onClick={this.props.daysStore.removeDay(this.props.trackingStore.state.date,'frei')} />}
              </span>
              Erfasste Zeiten für {moment(this.props.trackingStore.state.date).format('dddd, [den] DD. MMMM YYYY')}
            </h2>
            <WorkDoneList noSelect filter={e => !e.kind && moment(e.date).isSame(this.props.trackingStore.state.rawDate,'day')} scale={1.25} />
            <DeletePrompt tag="workDone" caption="workDone.delete" />
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <PanelHeading style={{fontSize: '24px'}} value="month.overview"
                label={this.props.monthStore.current} />
            <BigCalendar views={['month']} defaultView="month" style={{height: '400px'}}
                localizer={localizer}
                date={this.props.monthStore.selected}
                onNavigate={this.selectDate}
                events={this.props.workDoneStore.totals.get()}
                toolbar={false}
                startAccessor={e => e.date}
                endAccessor={e => e.date}
                titleAccessor={this.getDailyTitle}
                dayPropGetter={this.getDayStyling}
                eventPropGetter={this.getDailyStyling}
                slotPropGetter={this.getSlotStyling}
                allDayAccessor={(event) => true}
                messages={{
                  allDay : 'Ganztags',
                  previous : 'zurück',
                  next : 'vor',
                  today : 'heute',
                  month : 'Monat',
                  week : 'Woche',
                  work_week : 'Werktage',
                }}
            />
            <h3>Kopiervorlagen - vorheriger Werktag - {moment(this.props.trackingStore.state.prevWork).format('dddd D. MMM YYYY')}</h3>
            <WorkDoneList noSelect copycat filter={e => !e.kind && moment(e.date).isSame(this.props.trackingStore.state.prevWork,'day')} sorted={['start']} />
            <h3>Kopiervorlagen - vor einer Woche - {moment(this.props.trackingStore.state.prevWeek).format('dddd D. MMM YYYY')}</h3>
            <WorkDoneList noSelect copycat filter={e => !e.kind && moment(e.date).isSame(this.props.trackingStore.state.prevWeek,'day')} sorted={['start']} />
          </div>
          <div className="col-md-5">
            <MonthSelector selectable />
            <SheetsList />
            <DeletePrompt tag="sheet" caption="sheet.delete" />
            <MonthlyStats />
            <MissingSheets />
          </div>
        </div>
      </RS.Fade>
    </div>
    </div>
  }

  selectSlot = action(slotInfo => {
    this.props.monthStore.select(slotInfo.start)
    if (slotInfo.slots.length > 1) {
      this.props.workDoneStore.createWorkDone({
          sheetID: this.props.sheetsStore.selected.id,
          packetID: this.props.packetsStore.selected.id,
          month: this.props.monthStore.month.num,
          date: slotInfo.start,
          start: fromDate(slotInfo.start),
          stop: fromDate(slotInfo.end),
          time: fromDate(slotInfo.end) - fromDate(slotInfo.start),
      })()
    }
  })
  selectEntry = action(entry => {
    this.props.trackingStore.setDate(new Date(entry.date))
    this.props.monthStore.selectNum(entry.month)
    this.props.workDoneStore.select(entry)()
  })
  selectDate = date => {
    this.props.trackingStore.setDate(date)
    this.props.monthStore.select(date)
  }
  selectView = view => {
    this.props.trackingStore.setView(view)
  }

  getStart = entry => toDate(entry.date,entry.start)
  getStop  = entry => toDate(entry.date,entry.stop)
  getTitle = entry => <EntryTitle entry={entry} />

  getStyling = entry => {
    const selected = (moment(entry.date).isSame(this.props.trackingStore.state.rawDate,'day')) ? 'selected ' : ''
    if (entry.kind) {
      return {
        className: selected + entry.kind
      }
    } else {
      const packet = entry?.sheet?.packet
      const contractID = packet?.project
      if (!contractID) return {}
      const contract = this.props.contractsStore.contracts[contractID]
      const customColor = contract ? packet?.color ||
              contract.color ||
              contract.customer.color ||
              '#fdd' : '#faa'
      let customBorder = '#aaa'
      try {
        customBorder = shadeBlendConvert(-0.25, customColor)
      } catch(error) {
        //console.log(error)
      }

      return {
        style: {
          backgroundColor: customColor,
          borderColor: customBorder,
        }
      }
    }
  }
  getSlotStyling = date => {
    const classes =
        (moment(date).isSame(this.props.trackingStore.state.rawDate,'day') ?
          (this.props.trackingStore.state.view === 'day' ? 'selected-day ' : 'selected ') : '')
    return {
      className: cn(
        classes,
        this.props.holidaysStore.isHoliday(date) && 'holiday',
        this.props.daysStore.isOffDay(date)      && 'off-day',
        this.props.daysStore.isDone(date)        && 'done-day',
      ) || 'work-done',
    }
  }

  getDailyTitle = entry => {
    return (entry.kind) ?
      (<div style={{textAlign: 'center'}}>{entry.name}</div>) :
      (<div style={{textAlign: 'center'}}><TimeFormatter time={entry.time} /></div>)
  }
  getDayStyling = date => ({
    className: cn(
      this.props.holidaysStore.isHoliday(date) && 'holiday',
      this.props.daysStore.isOffDay(date)      && 'off-day',
      this.props.daysStore.isDone(date)        && 'done-day',
      moment(date).weekday() > 4               && 'weekend',
      moment(date).isSame(this.props.trackingStore.state.rawDate,'day') && 'selected',
    )
  })
  getDailyStyling = entry => ({
    className: cn(
      this.props.holidaysStore.isHoliday(entry.date) && 'holiday',
      this.props.daysStore.isOffDay(entry.date)      && 'off-day',
      this.props.daysStore.isDone(entry.date)        && 'done-day',
      moment(entry.date).isSame(this.props.trackingStore.state.rawDate,'day') && 'selected',
    ) || 'work-done',
  })
}
