import React                   from 'react'
import { inject, observer    } from 'mobx-react'
import { Provider            } from 'mobx-react'
import { action              } from 'mobx'

import { FieldLabel          } from '../../components/fields/label'

import { ColorSlider         } from '../../components/color'

import { DetailsTable        } from '../../components/DetailsTable'
import { DetailsTextEdit     } from '../../components/DetailsTextEdit'
import { DetailsTimeEdit     } from '../../components/DetailsTimeEdit'
import { DetailsTimeView     } from '../../components/DetailsTimeView'
import { DetailsCheckboxEdit } from '../../components/DetailsCheckboxEdit'
import { DetailsDateEdit     } from '../../components/DetailsDateEdit'
import { DetailsDateView     } from '../../components/DetailsDateView'

import { CustomerSelector    } from '../customers/selector'
import { customerToOption    } from '../customers'

import { AlertHelp           } from '../../components/AlertHelp'


@inject('customerStore','contractsStore')
@observer
export class ContractDetails extends React.Component {
  render() {
    if (!this.props.customerStore.selected.id) {
      return null
    } else if (!this.props.contractsStore.selected.id) {
      return <AlertHelp>Wählen Sie einen Auftrag durch Anklicken aus, um die Details zu bearbeiten und die zugehörigen Zeiterfassungs-Daten anzuzeigen.</AlertHelp>
    } else {
      const store = this.props.contractsStore
      const data = {
        store : store,
        update : store.update,
      }
      return (<Provider {...data}><div>
        <hr />
        <h4>Details zu Auftrag '{this.props.contractsStore.selected.name}'</h4>
        <DetailsTable>
          <colgroup>
            <col width="33%" />
            <col width="66%" />
          </colgroup>
          <tbody>
            <DetailsTextEdit label="field.ident" field="tag" />
            <DetailsTextEdit label="field.name" field="name" />
            <DetailsTextEdit label="field.alias" field="alias" />
            <DetailsDateEdit label="Laufzeit von" field="validFrom" />
            <DetailsDateEdit label="Laufzeit bis" field="validUntil" />
            <DetailsTimeEdit label="field.budget" field="budget" />
            <DetailsTimeView label="field.booked" field="size" />
            <DetailsTimeView label="field.remaining" field="rest" />
            <DetailsTextEdit label="field.rate" field="rate" />
            <tr>
              <FieldLabel value="field.color" uid="contract" />
              <td style={{border: 'solid 1px #ddbbcf', padding: '8px', margin: '-2px'}}><ColorSlider
                  color={this.props.contractsStore.selected.color || this.props.customerStore.selected.color}
                  onChange={this.setColor}
              /></td>
            </tr>
            <tr>
              <FieldLabel value="field.customer" />
              <td><CustomerSelector value={customerToOption(store.selected.customer)} onChange={store.assign} /></td>
            </tr>
            <DetailsCheckboxEdit label="field.locked" field="locked" />
            <DetailsDateView label="field.created" field="created" />
          </tbody>
        </DetailsTable>
      </div></Provider>)
    }
  }

  setColor = action(color => {
    this.props.contractsStore.selected.color = color.hex
    this.props.contractsStore.update(this.props.contractsStore.selected)
  })

}
