import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { ContentApi    } from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'
import { userStore     } from 'sdc-auth-user'

const typeID = 'MsyCTDCG1WMxrIRZepiY5oVVwFKrAMMs'

export class DaysStore extends UpdatingStore {

  @observable offDays = []

  constructor(options) {
    super({
      typeID,
      ...options,
    })
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
      }
    })
  }

  reload = () => {
    this.list({
      params: {owner: userStore.user.googleID},
      callback: this.parseOffDays,
    })()
  }

  parseOffDays = deferred => action(payload => {
    this.dataList = payload.content
    this.offDays = this.dataList.map(this.toOffDay)
    deferred.resolve(this.dataList)
  })

  isOffDay = date => _.find(toJS(this.offDays), d => moment(date).isSame(d.date,'day') && d.kind === 'off-day') !== undefined
  isDone   = date => _.find(toJS(this.offDays), d => moment(date).isSame(d.date,'day') && d.kind === 'done-day') !== undefined

  afterCreate = entry => {
    this.offDays.push(this.toOffDay(entry))
  }

  removeDay = (date,kind) => event => {
    const existing = _.find(toJS(this.dataList), d => moment(date).isSame(d.values.date,'day') && d.values.name === kind)
    if (existing) this.remove(existing)(event)
  }

  toOffDay = entry => ({
      name: entry.values.name,
      date: moment(entry.values.date),
      kind: this.modeFor(entry),
    })

  modeFor = entry => {
    const mode = entry.values.mode || ''
    if (mode === 'done') return 'done-day'
    return 'off-day'
  }

  afterRemove = entry => {
    this.offDays = this.offDays.filter(existing => !moment(entry.values.date).isSame(existing.date,'day'))
  }

  workingDays = days => days.filter(d => !this.isOffDay(d))

}

export const makeDaysStore = ({backend,...options}) => new DaysStore({...options,api: ContentApi({backend,typeID})})
