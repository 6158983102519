import React             from 'react'
import { observer      } from 'mobx-react'

import { PanelExpander } from '../../components/panels/expander'

import { PostalsList   } from './list'
import { PostalDetails } from './details'

export const PostalsPanel = observer(() =>
<PanelExpander value="addresses.heading" topic="addresses.intro" icon="address-card-o" expanded>
  <PostalsList />
  <PostalDetails />
</PanelExpander>)
