import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

export const EntryTag = inject('contractsStore')(observer(({contractsStore,entry}) => {
  const contractID = entry?.sheet?.packet?.project
  if (!contractID) return null
  const contract = contractsStore.contracts[contractID]
  return <div
      style={{fontSize: '85%', color: '#666', paddingBottom: '4px', fontWeight: 'bold'}}>
    {!entry.kind && entry.sheet && <React.Fragment>
      <span style={{color: '#888'}}>{contract?.customer?.alias}</span>
      <span> :: </span>
      <span>{entry.sheet.packet.alias || entry.sheet.packet.name}</span>
    </React.Fragment>}
    {entry.ident && <React.Fragment>
      <span> :: </span>
      <span style={{color: '#333'}}>{entry.ident}</span>
    </React.Fragment>}
  </div>
}))
