import React from 'react'
import { inject, observer } from 'mobx-react'

import { TimeFormatter } from '../../components/datetime/time'
import { Button        } from 'sdc-react-basics'

import { Header } from './header'

import './issues.scss'

export const Issues = inject('workDoneStore')(observer(({workDoneStore}) =>
(workDoneStore.identedSums() || []).length > 0 ? <React.Fragment>
  <Header copyAll={workDoneStore.copyAll} />
  {(workDoneStore.identedSums() || []).map(issue => <tr key={issue.ident} className="text-right">
    <th className="text-center">
      <Button className="float-left" style={{color: '#9bf', cursor: 'pointer'}} icon="copy" title="issue.copy"
          onClick={workDoneStore.copy(issue)}
      />
      <span className="issue-ident" onClick={workDoneStore.setIdent(issue.ident)}>{issue.ident}</span>
    </th>
    <td className="pr-3"><TimeFormatter inline time={issue.time} /></td>
    <td className="pr-3">{(issue.time/60.0).toFixed(2)}</td>
    <td className="pr-3">{(issue.time/600.0).toFixed(3)}</td>
  </tr>)}
  <tr style={{height: '4px'}}></tr>
</React.Fragment> : null))
