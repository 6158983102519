import { observable, action, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { ApolloClient  } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink      } from 'apollo-link-http'

import   gql             from 'graphql-tag'

import { ContentApi    } from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'

import { subscribe     } from 'sdc-publish-subscribe'

import { werktage } from '../../data/holidays/utils'

const typeID = 'vJfQvOVKbkhF4UlaZ7YljucJkX0kuHVM'

const cache = new InMemoryCache()
const link = new HttpLink({
  uri: 'https://api.scrumbill.com'
})
const client = new ApolloClient({
  cache,
  link
})


class HolidaysStore extends UpdatingStore {

  @observable holidays = []

  constructor(options) {
    super({
      ...options,
      typeID,
    })
    subscribe('month-selected', this.loadHolidays('month'))
    //this.loadAllHolidays()
    this.reload()
  }

  loadHolidays = (scope = 'month') => (date = moment()) => {
    console.log('loadHolidays()',scope,date)
    client.query({
      query: gql`
        query Holidays {
          holidays(${scope}: ${date.valueOf()}) {
            id
            name
            date
          }
        }
      `
    }).then(this.parseHolidays(scope))
  }

  clone = holiday => action(() => {
    const newly = toJS(holiday.values)
    console.log(newly)
    newly.date = moment(newly.date).add('year',1).valueOf()
    this.create({values : newly})()
  })

  lists = {
    month : 'monthly',
  }

  parseHolidays = scope => action(payload => {
    const listName = this.lists[scope] || 'dataList'
    this[listName] = payload.data.holidays || []
    if (scope === 'month') {
      this.holidays = this[listName].map(h => ({
        name: h.name,
        date: moment(h.date),
        kind: 'holiday',
      }))
    }
  })

  isHoliday = date => _(toJS(this.holidays)).find(h => moment(date).isSame(h.date,'day')) !== undefined

  workingDays = date => werktage(this, date)

}

export const makeHolidaysStore = ({backend,...options}) => new HolidaysStore({...options,api: ContentApi({backend,typeID})})
