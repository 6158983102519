import React from 'react'

import { Label } from 'sdc-i18n-react'

import './index.scss'

export const AboutTerms = () => <div className="about bordered-panel">

<h4><Label value="about.sb" /> :: <Label value="about.terms" /></h4>

<p className="large">The following document outlines the terms of use of the ScrumBill web site. You can also review
our <a href="/privacy">Privacy Policy</a>,
which outlines how we handle any personal information that you may provide to us.</p>

<p>Before using any of the ScrumBill services, you are required to read, understand, and agree to these terms.
You may only create an account after reading and accepting these terms. The column to the right provides
the gist of the terms of use and is not legally binding.</p>

<div className="section clearfix">
<div className="legal"><h6>Acceptance of Terms</h6>
<p>The web pages available at ScrumBill.net and all linked pages ("Site"),
are owned and operated by <a href="http://www.sdc-software.com">Software Design & Consulting GmbH</a> ("SDC"),
a German corporation, and is accessed by you under the Terms of Use described below ("Terms").</p>

<p>Please read these terms carefully before using the services.
By accessing the site, viewing any content or using any services
available on the site (as each is defined below) you are agreeing
to be bound by these terms, which together with our <a href="/privacy">Privacy policy</a>,
governs our relationship with you in relation to the site.
If you disagree with any part of the terms then you may not access the site.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>By using ScrumBill you agree to all the terms below.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Description of Service</h6>
<p>The Site is an online service which enables registered users to post a log of their work related to projects or contracts,
and access and/or purchase services from time to time made available on the Site ("Services").
Services include, but are not limited to, any service and/or content ScrumBill makes available
to or performs for you, as well as the offering of any materials displayed,
transmitted or performed on the Site or through the Services. Content ("Content") may include,
but is not limited to text, user comments, messages, information, data, graphics, news articles,
photographs, images, illustrations, and software.</p>

<p>Your access to and use of the Site may be interrupted from time to time as a result
of equipment malfunction, updating, maintenance or repair of the Site or
any other reason within or outside the control of ScrumBill. ScrumBill reserves the right
to suspend or discontinue the availability of the Site and/or any Service and/or
remove any Content at any time at its sole discretion and without prior notice.
ScrumBill may also impose limits on certain features and Services or restrict your access
to parts of or all of the Site and the Services without notice or liability.
The Site should not be used or relied upon for storage of your texts and other data
and you are directed to retain your own copies of all Content posted on the Site.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>We develop and provide services to log, review, and report on work related to projects or contracts
and we will develop more features and services in the future.
At times things can go wrong and the service may be interrupted.
Unlikely, but sometimes things can go really wrong.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Registration</h6>
<p>As a condition to using Services, you are required to open an account with ScrumBill
and select a password and user name, and to provide registration information.
The registration information you provide must be accurate, complete, and current at all times.
Failure to do so constitutes a breach of the Terms,
which may result in immediate termination of your ScrumBill account.</p>

<p>You may not use as a user name the name of another person or entity
or that is not lawfully available for use, a name or trade mark that is subject
to any rights of another person or entity other than you without appropriate authorization,
or a name that is otherwise offensive, vulgar or obscene.</p>

<p>You are responsible for maintaining the confidentiality of your password
and are solely responsible for all activities resulting from the use
of your password and conducted through your ScrumBill account.</p>

<p>Services are available only to individuals who are either
(i) at least 18 years old, or (ii) at least 14 years old,
and who are authorized to access the Site by a parent or legal guardian.
If you have authorized a minor to use the Site, you are responsible for
the online conduct of such minor, and the consequences of any misuse of the Site by the minor.
Parents and legal guardians are warned that the Site does display
texts describing nudity and violence that may be offensive to some.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>To fully use the services you need to create your own account,
without violating other people's rights.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>User Conduct</h6>
<p>All Content posted or otherwise submitted to the Site is the sole responsibility of the account holder
from which such Content originates and you acknowledge and agree that you, and not ScrumBill are entirely
responsible for all Content that you post, or otherwise submit to the Site. ScrumBill does not control user
submitted Content and, as such, does not guarantee the accuracy, integrity or quality of such Content.
You understand that by using the Site you may be exposed to Content that is offensive, indecent or objectionable.</p>
<p>As a condition of use, you promise not to use the Services for any purpose that is unlawful or prohibited
by these Terms, or any other purpose not reasonably intended by ScrumBill. By way of example, and not as a limitation,
you agree not to use the Services:</p>
<ol>
<li>To abuse, harass, threaten, impersonate or intimidate any person;</li>
<li>To post or transmit, or cause to be posted or transmitted, any Content that is libellous, defamatory, obscene,
pornographic, abusive, offensive, profane, or that infringes any copyright or other right of any person;</li>
<li>For any purpose (including posting or viewing Content) that is not permitted under the laws of the jurisdiction
where you use the Services;</li>
<li>To post or transmit, or cause to be posted or transmitted, any communication or solicitation designed or intended
to obtain password, account, or private information from any ScrumBill user;</li>
<li>To create or transmit unwanted ‘spam’ to any person or any URL</li>
<li>To create multiple accounts for the purpose of voting for or against users’ texts or reviews;</li>
<li>To post copyrighted Content which doesn’t belong to you, with exception of Blogs, where you may post such Content
with explicit mention of the author’s name and a link to the source of the Content;</li>
<li>With the exception of accessing RSS feeds, you will not use any robot, spider, scraper or other automated means
to access the Site for any purpose without our express written permission. Additionally, you agree that you will
not: (i) take any action that imposes, or may impose in our sole discretion an unreasonable or disproportionately
large load on our infrastructure; (ii) interfere or attempt to interfere with the proper working of the Site or any
activities conducted on the Site; or (iii) bypass any measures we may use to prevent or restrict access to
the Site;</li>
<li>To artificially inﬂate or alter vote counts, blog counts, comments, or any other Service or for the purpose
of giving or receiving money or other compensation in exchange for votes, or for participating in any other
organized effort that in any way artificially alters the results of Services;</li>
<li>To advertise to, or solicit, any user to buy or sell any products or services, or to use any information
obtained from the Services in order to contact, advertise to, solicit, or sell to any user without their prior
explicit consent;</li>
<li>To promote or sell Content of another person</li>
<li>To sell or otherwise transfer your profile.</li>
</ol>
<p>To report a suspected abuse of the Site or a breach of the Terms (other than relating to copyright infringement
which is addressed under “COPYRIGHT COMPLAINTS” below) please send written notice to ScrumBill at email:
help@ScrumBill.com.</p>
<p>You are solely responsible for your interactions with other users of the Site. ScrumBill reserves the right, but has
no obligation, to monitor disputes between you and other users.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>You cannot use our site to post pornographic material, harass people, send spam, and do other crazy stuff.
Be reasonable and responsible, don't do anything stupid and you'll be fine.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Content Submitted Or Made Available For Inclusion On The Service</h6>
<p>Please read this section carefully before posting, uploading, or otherwise submitting any Content to the site.
By submitting content to the site you are granting ScrumBill a worldwide, Non exclusive license to use the content and
are representing and warranting to ScrumBill That the content is owned or duly licensed by you, and that ScrumBill is free
to publish, Distribute and use the content as hereinafter provided for without obtaining permission Or license from
any third party.</p>
<p>In consideration of ScrumBill’s agreement to allow you to post Content to the Site and ScrumBill’s agreement to publish
such Content and for other valuable consideration the receipt and sufficiency of which are hereby expressly and
irrevocably acknowledged, you agree with ScrumBill as follows:</p>
<ol>
<li>You acknowledge that:
<ol>
<li>By uploading your textual or graphic works to ScrumBill you retain full rights to those works that you had prior
to uploading.</li>
<li>By posting Content to the Site you hereby grant to ScrumBill a non-exclusive, transferable, fully paid, worldwide
license (with the right to sublicense) to use, distribute, reproduce, modify, adapt, publicly perform and publicly
display such Content in connection with the Services. This license will exist for the period during which the
Content is posted on the Site and will automatically terminate upon the removal of the Content from the Site;</li>
<li>The license granted to ScrumBill includes the right to use your Content fully or partially for promotional reasons
and to distribute and redistribute your Content to other parties, web-sites, applications, and other entities,
provided such Content is attributed to you in accordance with the credits (i.e. username, profile picture, project or
contract title, descriptions, tags, and other accompanying information) if any and as appropriate, all as submitted
to ScrumBill by you;</li>
<li>ScrumBill uses industry recognized software and measures to restrict the ability of users and visitors to the Site
to make copies of Content posted on the Site that do not carry any attribution, watermark, or other copy protection.
Notwithstanding this, ScrumBill makes no representation and warranty that Content posted on the Site
will not be unlawfully copied without your consent.
ScrumBill does not restrict the ability of users and visitors to the Site to make watermarked
and fully attributed copies of Content posted on the Site and you hereby expressly authorize ScrumBill to permit users
and visitors to the Site to make such watermarked and attributed copies of your Content; and</li>
<li>Subject to the terms of the foregoing license, you retain full ownership or other rights in your Content and
any intellectual property rights or other proprietary rights associated with your Content. </li>
</ol></li>
<li>You represent and warrant that:
<ol>
<li>You are the owner of all rights, including all copy rights in and to all Content you submit to the site;</li>
<li>You have the full and complete right to enter into this agreement and to grant to ScrumBill the rights in the
Content herein granted, and that no further permissions are required from, nor payments required to be made to
any other person in connection with the use by ScrumBill of the Content as contemplated herein; and</li>
<li>The Content does not defame any person and does not infringe upon the copyright, moral rights, publicity rights,
privacy rights or any other right of any person, or violate any law or judicial or governmental order.</li>
</ol></li>
<li>You shall not have any right to terminate the permissions granted herein, nor to seek, obtain, or enforce any
injunctive or other equitable relief against ScrumBill, all of which such rights are hereby expressly and irrevocably
waived by you in favour of ScrumBill.</li>
</ol>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Your data will preserve whatever copyright they had before uploading to this site.
We will protect the copyright and will not sell your data without your permission.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Fair Usage Policy</h6>
<p>ScrumBill maintains a fair usage policy to ensure stable and fast service to all users.</p>
<p>Free accounts are limited to a maximum of 100 new texts and 10 new images per week and
20,000 texts / 2,000 images in total
(approximately 60GB of storage) and 1GB of data transfer from profile and projects per month.
Any additional usage may result in restrictions on your account including limited access to your projects
or a requirement to upgrade if the limit is exceeded for several months.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>If you use more than your fair share, we may gradually limit your account.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Copyright Complaints</h6>
<p>ScrumBill respects the intellectual property rights of others. It is our policy to respond promptly any claim
that Content posted on the Site infringes the copyright or other intellectual property infringement (“Infringement”)
of any person. ScrumBill will use reasonable efforts to investigate notices of alleged Infringement and will take
appropriate action under applicable intellectual property law and these Terms where it believes an Infringement
has taken place, including removing or disabling access to the Content claimed to be infringing and/or terminating
accounts and access to the Site.</p>
<p>To notify ScrumBill of a possible Infringement you must submit your notice in writing to the attention of
“Copyright Infringement” care of help@ScrumBill.net and include in your notice a detailed description of the
alleged Infringement sufficient to enable ScrumBill to make a reasonable determination. Please note that you
may be held accountable for damages (including costs and attorneys’ fees) for misrepresenting that any Content
is infringing your copyright.</p>
<p>If we remove or disable access to Content in response to a notice of Infringement, we will make reasonable
attempts to contact the user who posted the affected Content. If you feel that your Content is not infringing,
you may provide ScrumBill with a counter notice in writing to the attention of “Copyright Infringement Counter
Notification” at help@ScrumBill.net. You must include in your counter notice sufficient information to enable ScrumBill
to make a reasonable determination. Please note that you may be held accountable for damages (including costs
and attorneys' fees) if you materially misrepresent that your Content is not infringing the copyrights of others.</p>
<p>If you are uncertain whether an activity constitutes Infringement, we recommended seeking advice of an
attorney.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>We respect copyright. If anything is wrong, please send an email with all the details to <a
href="mailto:help@ScrumBill.net">help@ScrumBill.net</a>.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Links</h6>
<p>The Services may provide, or third parties may provide, links to other World Wide Web sites or resources.
Because ScrumBill has no control over such sites and resources, you acknowledge and agree that ScrumBill is not
responsible for the availability of such external sites or resources, and does not endorse and is not
responsible or liable for any content, advertising, products or other materials on or available from such
sites or resources. You further acknowledge and agree that ScrumBill shall not be responsible or liable, directly
or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance
on any such content, goods or services available on or through any such site or resource.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Some people may post links, we are not responsible for those links.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Release And Indemnity</h6>
<p>You hereby expressly and irrevocably release and forever discharge ScrumBill, its affiliated and associated
companies, and their respective directors, officers, employees, agents, representatives, independent and dependent
contractors, licensees, successors and assigns of and from any and all actions, causes of action, suits,
proceedings, liability, debts, judgments, claims and demands whatsoever in law or equity which you ever had,
now have, or hereafter can, shall or may have, for or by reason of, or arising directly or indirectly out of
your use of the Site and the Services.</p>
<p>You hereby agree to indemnify and hold harmless ScrumBill, its affiliated and associated companies, and their
respective directors, officers, employees, agents, representatives, independent and dependent contractors,
licensees, successors and assigns from and against all claims, losses, expenses, damages and costs (including,
but not limited to, direct, incidental, consequential, exemplary and indirect damages), and reasonable attorneys'
fees, resulting from or arising out of (i) a breach of these Terms, (ii) Content posted on the Site, (iii) the use
of the Services, by you or any person using your account or ScrumBill Username and password, (iv) the sale or use of
your Store Images, or (v) any violation of any rights of a third party.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>We are not liable if something goes really wrong. Always have a backup of your texts and data.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Limitation Of Liability</h6>
<p>In no event shall ScrumBill be liable under contract, tort, strict liability, negligence or other Legal theory
with respect to the site, the service or any content (i) for any lost profits or Special, indirect, incidental,
punitive, or consequential damages of any kind whatsoever, (ii)</p>
<p>To provide substitute goods or services (however arising), or (iii) for any direct damages in Excess of
(in the aggregate) $100.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Again, we are not liable. But we may pay you $100 to provide substitute.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Trademarks</h6>
<p>ScrumBill, ScrumBill.net and other ScrumBill graphics, logos, designs, page headers, button icons, scripts, and service
names are registered trademarks, trademarks or trade dress of ScrumBill. ScrumBill’s trademarks and trade dress may not
be used in connection with any product or service without the prior written consent of ScrumBill. The images and icons
available in the ScrumBill icon pack may used by partners and third party sites in connection with providing
appropriate links to the ScrumBill Site.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Please respect our trademarks and brands.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Termination</h6>
<p>ScrumBill may terminate or suspend any and all Services and/or your ScrumBill account immediately,
without prior notice or liability, for any reason whatsoever, including without limitation if
you breach the Terms. Upon termination of your account, your right to use the Services will immediately cease.
If you wish to terminate your ScrumBill account, you may simply discontinue using the Services.
All provisions of the Terms which by their nature should survive termination shall survive termination,
including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
<p>It is your responsibility to remove all Content from your account prior to termination.
Upon termination of your account ScrumBill will automatically remove all Content posted to your account.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>We may stop providing services at any time. You can stop using your account or close it at any time as well.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Change</h6>
<p>ScrumBill reserves the right, at its sole discretion, to modify or replace the terms at any time.
If the alterations constitute a material change to the terms, ScrumBill will notify you by posting an
announcement on the site. What constitutes a material change will be determined at ScrumBill’s sole discretion.
You shall be responsible for reviewing and becoming familiar with any such modifications.
Using any service or viewing any content following notification of a material change to the terms
shall constitute your acceptance of the Terms as modified.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>If these terms of use change, we will notify you.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Miscellaneous</h6>
<p>No agency, partnership, joint venture, or employment is created as a result of the Terms
and you do not have any authority of any kind to bind ScrumBill in any respect whatsoever.
The failure of either party to exercise in any respect any right provided for herein
shall not be deemed a waiver of any further rights hereunder. ScrumBill shall not be liable
for any failure to perform its obligations hereunder where such failure results from any
cause beyond ScrumBill’s reasonable control, including, without limitation, mechanical, electronic
or communications failure or degradation (including "line-noise" interference).
If any provision of the Terms is found to be unenforceable or invalid, that provision shall be
limited or eliminated to the minimum extent necessary so that the Terms shall otherwise remain
in full force and effect and enforceable. ScrumBill may transfer, assign or delegate the Terms and
its rights and obligations without consent. The Terms shall be governed by and construed in accordance
with the laws of Germany, as if made within Germany between two residents thereof, and the parties
submit to the exclusive jurisdiction of German courts. Both parties agree that the Terms is the
complete and exclusive statement of the mutual understanding of the parties and supersedes and
cancels all previous written and oral agreements, communications and other understandings relating to
the subject matter of the Terms, and that all modifications must be in a writing signed by both parties,
except as otherwise provided herein.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Things can happen &mdash; we are not responsible.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Effective Date of these Terms of Service</h6>
<p>These Terms of Service are effective as of December 7, 2012.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Effective as of December 7, 2012.</p>
</div>
</div>

</div>
