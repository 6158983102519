import React from 'react'
import { inject, observer } from 'mobx-react'

import * as RS from 'reactstrap'

import { AlertHint } from '../../components/AlertHint'

import './MissingSheets.scss'

const Auftrag = ({auftrag}) => <span>
  Auftrag '{auftrag.tag} - {auftrag.name}'
</span>


export const MissingSheets = inject('contractsStore','packetsStore','sheetsStore')(observer(
({contractsStore,packetsStore,sheetsStore}) => {
  if (sheetsStore.dataList.length === 0) {
    if (contractsStore.selected.id) {
      const packetCount = packetsStore.dataList.filter(p => !p.locked && p.project === contractsStore.selected.id).length
      return <AlertHint>
        Wählen Sie zuerst ein Paket / Projekt aus und legen Sie für diesen Monat ein Sheet an.
        <hr/>
        Es sind noch keine Sheets für die Pakete / Projekte zum <Auftrag auftrag={contractsStore.selected} /> angelegt worden.
        <div className="missing-sheets">
          <RS.Button color="info" outline onClick={packetsStore.createMissing}>Für alle {packetCount} Pakete zum <Auftrag auftrag={contractsStore.selected} /> jetzt anlegen</RS.Button>
        </div>
      </AlertHint>
    } else {
      return <AlertHint>
        Wählen Sie zuerst ein Paket / Projekt aus und legen Sie für diesen Monat ein Sheet an.
      </AlertHint>
    }
  }
  if (contractsStore.selected.id) {
    const packetCount = packetsStore.dataList.filter(p => !p.locked && p.project === contractsStore.selected.id).length
    const sheetCount = sheetsStore.dataList.filter(s => s.packet?.project === contractsStore.selected.id).length
    if (sheetCount === 0) {
      const packetCount = packetsStore.dataList.filter(p => p.project === contractsStore.selected.id).length
      return <AlertHint>
        Wählen Sie zuerst ein Paket / Projekt aus und legen Sie für diesen Monat ein Sheet an.
        <hr/>
        Es sind noch keine Sheets für die Pakete / Projekte zum <Auftrag auftrag={contractsStore.selected} /> angelegt worden.
        {contractsStore.selected.id && <div className="missing-sheets">
          <RS.Button color="info" outline onClick={packetsStore.createMissing}>Für alle {packetCount} Pakete zum <Auftrag auftrag={contractsStore.selected} /> jetzt anlegen</RS.Button>
        </div>}
      </AlertHint>
    } else if (sheetCount < packetCount) {
      return <AlertHint>
        Es sind noch nicht alle Sheets für die Pakete / Projekte zum <Auftrag auftrag={contractsStore.selected} /> angelegt worden.
        {contractsStore.selected.id && <div className="missing-sheets">
          <RS.Button color="info" outline onClick={packetsStore.createMissing}>Für {packetCount-sheetCount} weitere Pakete zum <Auftrag auftrag={contractsStore.selected} /> jetzt anlegen</RS.Button>
        </div>}
      </AlertHint>
    }
  }
  return null
}))
