import { observable, action } from 'mobx'

import moment from 'moment'

class TrackingStore {

  @observable state = {}

  constructor() {
    this.setDate(new Date())
  }

  @action
  setDate = date => {
    const rawDate = date.valueOf()
    const day = moment(rawDate)
    const prevWork = moment(rawDate).add(-(day.format('dd') === 'Mo' ? 3 : 1),'days').toDate().valueOf()
    const prevWeek = moment(rawDate).add(-7,'days').toDate().valueOf()
    this.state = {
      view: this.state.view || 'day',
      date,
      rawDate,
      prevWork,
      prevWeek,
    }
  }

  @action
  setView = view => {
    this.state.view = view
  }
}

export default new TrackingStore()
