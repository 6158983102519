import React from 'react'
import { inject, observer } from 'mobx-react'

import { Empty  } from './empty'
import { Booked } from './booked'

export const Planning = inject('monthsStore')(observer(({monthsStore}) => {
  const { bookedTime } = monthsStore.selected.values || {}
  return <React.Fragment>
    {bookedTime === 0 && <Empty />}
    {bookedTime !== 0 && <Booked />}
  </React.Fragment>
}))
