import React                from 'react'
import { inject, observer } from 'mobx-react'
import moment               from 'moment'

import { AlertHelp        } from 'sdc-cms-help'
import { MarkdownPanel    } from 'sdc-markdown-viewer'

import { PanelHeading     } from '../../components/panels/heading'

import { PostalView       } from '../../crm/postal/view'

import { PrintTextButton  } from './printButton'

import './preview.scss'

export const TextPreview = inject('textsStore','partnersStore','postalStore','authStore')(observer(
({textsStore,partnersStore,postalStore,authStore,...props}) => {
  if (!textsStore.selected.id) {
    return <div {...props}>
      <PanelHeading value="preview.heading" topic="preview.intro" icon="eye" />
      <AlertHelp topic="letter.preview.help" />
    </div>
  } else {
    const letter   = textsStore.selected.values || {}
    const sender   = postalStore.postals[letter.source] || {}
    const adressat = partnersStore.partners[textsStore.selected.values.to] || {}
    const partner  = adressat.values || {}
    return <div {...props}>
      <PanelHeading value="preview.heading" topic="preview.intro" icon="eye" />
      <div className="letter-preview">
        <PostalView postal={sender} className="source" withDetails />
        <PostalView postal={postalStore.postals[letter.target]} className="target" />
        <div className="datum">{moment().format('D. MMMM YYYY')}</div>
        <div className="betreff" onClick={textsStore.afterSelect}>Betreff: {letter.name || '<<Betreff>>'}</div>
        <div className="anrede" onClick={partnersStore.select(adressat)} >{partner.anrede || '<<Anrede>>'},</div>
        <MarkdownPanel className="text" text={letter.text} ignoreIndex onClick={textsStore.afterSelect} />
        <div className="gruss" onClick={partnersStore.select(adressat)}>{partner.gruss || '<<Grußformel>>'},</div>
        <div className="signed" onClick={postalStore.select(sender)}>{sender.name}</div>
        {letter.anlagen && <div className="anlagen">Anlage(n): {letter.anlagen}</div>}
      </div>
      <div className="letter-actions">
        <PrintTextButton entry={textsStore.selected} />
      </div>
    </div>
  }
}))
