import React from 'react'

import { PanelHeading  } from '../../components/panels/heading'

import { CustomersList } from './list'

export const CustomersPanel = props => <React.Fragment>
  <PanelHeading value="customers.heading" topic="customers.intro" icon="users" />
  <CustomersList {...props} />
</React.Fragment>
