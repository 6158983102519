import { action } from 'mobx'

import { api } from './api'

import { AuthenticatedStore } from '../../data/AuthenticatedStore'


export class BinariesStore extends AuthenticatedStore {

  constructor(options) {
    super({
      ...options,
      listName  : 'binaries',
      entryName : 'binary',
    })
  }

  @action
  addBinary = file => {
    const data = new FormData()
    data.append('fileName', file[0].name)
    data.append('mimeType', file[0].type)
    data.append('size',     file[0].size)
    data.append('content',  file[0])
    return this.create(data)()
  }

}

export const makeBinariesStore = ({backend,...options}) => new BinariesStore({...options,api : api({backend})})
