import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider } from 'mobx-react'

import { DetailsTable } from '../../components/DetailsTable'
import { DetailsTextEdit } from '../../components/DetailsTextEdit'
import { DetailsDateView } from '../../components/DetailsDateView'

@inject('binariesStore')
@observer
export class BinaryDetails extends React.Component {
  render() {
    const store = this.props.binariesStore
    const data = {
      store : store,
      update : store.update,
    }
    return (<Provider {...data}><DetailsTable>
      <colgroup>
        <col width="33%" />
        <col width="66%" />
      </colgroup>
      <tbody>
        <DetailsTextEdit label="Name" field="name" />
        <DetailsTextEdit label="Kürzel" field="alias" />
        <DetailsDateView label="Angelegt" field="created" />
      </tbody>
    </DetailsTable></Provider>)
  }
}
