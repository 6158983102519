import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { DetailsTable     } from '../../components/DetailsTable'
import { DetailsTextEdit  } from '../../components/DetailsTextEdit'
import { DetailsDateView  } from '../../components/DetailsDateView'


@inject('accountsStore')
@observer
export class AccountDetails extends React.Component {
  render() {
    const data = {
      store : this.props.accountsStore,
      update : this.props.accountsStore.update,
    }
    return (<Provider {...data}><DetailsTable>
      <colgroup>
        <col width="33%" />
        <col width="66%" />
      </colgroup>
      <tbody>
        <DetailsTextEdit label="Name" field="name" />
        <DetailsTextEdit label="E-Mail" field="email" />
        <DetailsTextEdit label="ID" field="googleID" />
        <DetailsDateView label="angelegt" field="added" />
        <DetailsDateView label="zuletzt online" field="lastSeen" fmt="DD.MM.YYYY HH:mm" />
      </tbody>
    </DetailsTable></Provider>)
  }
}
