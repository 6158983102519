import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { DetailsPanel     } from '../../components/DetailsPanel'
import { DetailsTable     } from '../../components/DetailsTable'
import { editingType      } from 'sdc-cms-writing'
import { ValueTextEdit    } from 'sdc-cms-editing'
import { DetailsDateView  } from '../../components/DetailsDateView'

import { PostalSelector   } from '../../crm/postal/selector'


export const PartnerDetails = inject('editingStore','partnersStore','typesStore','postalStore')(observer(
({editingStore: store,partnersStore,typesStore : types,postalStore}) => {
  const data = {
    dataStore : partnersStore,
    store     : store,
    update    : store.update,
  }
  return (partnersStore.selected.id && editingType.typeID === partnersStore.typeID) ? <Provider {...data}>
    <DetailsPanel value="partner.details">
      <DetailsTable>
        <colgroup>
          <col width="18%" />
          <col width="80%" />
        </colgroup>
        <tbody>
          <tr>
            <td>Adresse</td>
            <td><PostalSelector value={postalStore.postal(store.selected.values.postal)} onChange={store.updateChoice(types.fields.postal)} /></td>
          </tr>
          <DetailsDateView label="Angelegt" field="created" />
        </tbody>
      </DetailsTable>
      <ValueTextEdit field="name" />
      <ValueTextEdit field="anrede" />
      <ValueTextEdit field="gruss" />
    </DetailsPanel>
  </Provider> : null
}))

