import React from 'react'
import { inject, observer } from 'mobx-react'

import { Editor } from '../Editor'

export const TextCellEditor = ({cell,update}) => (
  <Editor contentEditable suppressContentEditableWarning
    onFocus={(e) => {
      window.getSelection().selectAllChildren(e.target)
    }}
    onBlur={(e) => {
      const field = cell.column.id
      const value = e.target.textContent
      update({
        ...cell.original,
        [field] : value,
      })
    }}
  >{cell.value}</Editor>
)

@inject('store','update')
@observer
export class TextFieldEditor extends React.Component {
  render() {
    return <Editor contentEditable suppressContentEditableWarning
      onFocus={(e) => {
        window.getSelection().selectAllChildren(e.target)
      }}
      onBlur={(e) => {
        const value = e.target.textContent
        this.props.update({
          ...this.props.store.selected,
          [this.props.field] : value.trim(),
        })
      }}
    >{this.props.store.selected[this.props.field] || this.props.defaultValue}</Editor>
  }
}
