import React from 'react'
import { inject, observer } from 'mobx-react'

import { AlertHelp        } from 'sdc-cms-help'

import { PanelHeading     } from './panels/heading'


export const DetailsPanel = inject('dataStore')(observer(
({dataStore : store,value,placeholder,borderTop=true,borderBottom,children,...props}) =>
{
  if (store.selected.id) {
    const label = store.selected.values ? store.selected.values.name : store.selected.name
    return <React.Fragment>
      {borderTop && <hr/>}
      <PanelHeading value={value} label={label} {...props}
        icon="caret-up btn" onClick={store.clearSelected} />
      {children}
      {borderBottom && <hr/>}
    </React.Fragment>
  } else if (placeholder) {
    return <AlertHelp topic={placeholder} />
  } else {
    return null
  }

}))
