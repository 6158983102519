import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore    } from 'sdc-auth-user'
import { CreateButton } from '../../components/buttons'

export const CreatePartnerButton = inject('partnersStore')(observer(({authStore,partnersStore}) =>
<CreateButton title="partner.create" onClick={partnersStore.create({
  owner  : userStore.user.id,
  values: {
    created: new Date().valueOf(),
  },
})} />))
