import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'

import BigCalendar from '@sdc-software/react-big-calendar'
import '@sdc-software/react-big-calendar/lib/css/react-big-calendar.css'

import momentLocalizer from 'react-widgets-moment'

import moment from 'moment'

import { Route, Router } from 'react-router'

import CookieBanner from 'react-cookie-banner'

import { NavBar }  from './nav/NavBar'

import { Home }    from './home'

import { history } from '../index'

import { uiBase }      from '../env/constants'
import { loginURL }    from '../user/session/urls'

import { TimeSheet, sheetURL, EntryMask, maskURL } from '../tracking'
import { BackOffice,   boURL      } from '../bo'
import { CRM,          crmURL     } from '../crm'
import { UserPanel,    userURL    } from '../user'
import { LettersPanel, lettersURL } from '../letters'

import { Authenticated, Administrator, LoginPanel } from 'sdc-auth-react'
import { GoogleLoginPanel } from 'sdc-auth-google'

import { Footer } from './footer'
import * as about from './about'
import { AboutScrumBill } from './about/about'
import { AboutLegal     } from './about/legal'
import { AboutPrivacy   } from './about/privacy'
import { AboutTerms     } from './about/terms'

import './app.scss'


export const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer
momentLocalizer()


@inject('labelsStore')
@observer
export class App extends Component {
  render() {
    return (<Router history={history}><div lang={this.props.labelsStore.language} style={{margin: 0, padding: 0}}>
      <NavBar />

      <div className="sdc-main-content">
        <CookieBanner dismissOnScroll={false}
            message="This website is using cookies to track your user ID and to generally improve your experience. According to current EU laws, as of May 25th 2018, you must actively consent to cookie usage before being able to use this site." />
        <Route path={uiBase} exact component={Home} />
        <Route path={loginURL}  component={() => <LoginPanel redirect={maskURL}>
          <GoogleLoginPanel clientID="903539049305-8n5gvhsu7k1btq088mbmqudgcd89anu4.apps.googleusercontent.com" />
        </LoginPanel>} />

        <Route path={sheetURL}   component={Authenticated(TimeSheet)} />
        <Route path={maskURL}    component={Authenticated(EntryMask)} />
        <Route path={crmURL}     component={Authenticated(CRM)} />
        <Route path={lettersURL} component={Authenticated(LettersPanel)} />
        <Route path={userURL}    component={Authenticated(UserPanel)} />
        <Route path={boURL}      component={Administrator(BackOffice)} />

        <Route path={about.aboutURL}   component={AboutScrumBill} />
        <Route path={about.legalURL}   component={AboutLegal} />
        <Route path={about.privacyURL} component={AboutPrivacy} />
        <Route path={about.termsURL}   component={AboutTerms} />
      </div>

      <Footer />

    </div></Router>)
  }
}
