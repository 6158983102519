import React                  from 'react'
import { action, observable } from 'mobx'
import { observer           } from 'mobx-react'
import { FieldLabel         } from '../fields/label'

import { ErrorBoundary      } from '../error/boundary'

import './heading.scss'


@observer
export class PanelExpander extends React.Component {
  @observable uiState = {
    expanded : this.props.expanded || false
  }
  @action
  toggle = () => {
    if (!this.props.alwaysOpen) this.uiState = {
      ...this.uiState,
      expanded : !this.uiState.expanded,
    }
  }
  render() {
    return <ErrorBoundary>
      <h4 className={'panel-heading '+ (this.uiState.expanded ? 'expanded' : 'collapsed')}
          style={this.props.style || {}} onClick={this.toggle}>
        {this.props.icon && <i className={'fa fa-'+this.props.icon} onClick={this.props.onClick} />}
        {!this.props.alwaysOpen && this.uiState.expanded &&  <i className="float-right fa fa-caret-up" />}
        {!this.props.alwaysOpen && !this.uiState.expanded && <i className="float-right fa fa-caret-down" />}
        <FieldLabel {...this.props} as={React.Fragment} />
      </h4>
      {(this.props.alwaysOpen || this.uiState.expanded) && this.props.children}
    </ErrorBoundary>
  }
}
