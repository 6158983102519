import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { DetailsPanel     } from '../../components/DetailsPanel'
import { DetailsTable     } from '../../components/DetailsTable'
import { DetailsTextEdit  } from '../../components/DetailsTextEdit'
import { DetailsDateView  } from '../../components/DetailsDateView'

@inject('postalStore')
@observer
export class PostalDetails extends React.Component {
  render() {
    const data = {
      dataStore : this.props.postalStore,
      store     : this.props.postalStore,
      update    : this.props.postalStore.update,
    }
    return <Provider {...data}>
      <DetailsPanel placeholder="postals.select" value="postal.details" borderBottom >
        <DetailsTable>
          <colgroup>
            <col width="33%" />
            <col width="66%" />
          </colgroup>
          <tbody>
            <DetailsTextEdit label="field.name"     field="name" />
            <DetailsTextEdit label="postal.zusatz"  field="zusatz" />
            <DetailsTextEdit label="postal.street"  field="street" />
            <DetailsTextEdit label="postal.zipCode" field="zipCode" />
            <DetailsTextEdit label="postal.city"    field="city" />
            <DetailsTextEdit label="postal.fon"     field="fon" />
            <DetailsTextEdit label="postal.fax"     field="fax" />
            <DetailsTextEdit label="field.url"      field="url" />
            <DetailsTextEdit label="field.email"    field="email" />
            <DetailsDateView label="field.created"  field="created" />
          </tbody>
        </DetailsTable>
      </DetailsPanel>
    </Provider>
  }
}
