import React from 'react'

import './AlertHint.scss'


export class AlertHint extends React.Component {
  render() {
    const {className='',...otherProps} = this.props
    return <div className={'alert-hint alert alert-warning '+className}
      {...otherProps}>{this.props.children}
    </div>
  }
}
