import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider        } from 'mobx-react'

import { LogoSelector    } from '../binaries/LogoSelector'
import { PostalSelector  } from '../postal/selector'
import { postalToOption  } from '../postal'

import { DetailsTable    } from '../../components/DetailsTable'
import { DetailsTextEdit } from '../../components/DetailsTextEdit'
import { DetailsDateView } from '../../components/DetailsDateView'

@inject('companiesStore')
@observer
export class CompanyDetails extends React.Component {
  render() {
    const store = this.props.companiesStore
    const data = {
      store : store,
      update : store.update,
    }
    return (<Provider {...data}><DetailsTable>
      <colgroup>
        <col width="33%" />
        <col width="66%" />
      </colgroup>
      <tbody>
        <DetailsTextEdit label="Name" field="name" />
        <DetailsTextEdit label="Kürzel" field="alias" />
        <tr>
          <th>Logo</th>
          <td><LogoSelector value={store.selected.logo} onChange={store.assignLogo} /></td>
        </tr>
        <DetailsTextEdit label="Handelsregister" field="entry" />
        <DetailsTextEdit label="Hauptsitz" field="place" />
        <DetailsTextEdit label="Gerichtsstand" field="judge" />
        <DetailsTextEdit label="Steuer-Nummer" field="taxID" />
        <DetailsTextEdit label="USt-Nummer" field="ustID" />
        <tr>
          <th>Post-Anschrift</th>
          <td><PostalSelector value={postalToOption(store.selected.postal)} onChange={store.assign} /></td>
        </tr>
        <DetailsDateView label="Angelegt" field="created" />
      </tbody>
    </DetailsTable></Provider>)
  }
}
