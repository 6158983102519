import { requiredParam } from 'sdc-utilities'

const baseUrl = 'accounts'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  create : ()   => () => backend.post(baseUrl),
  update : user => () => backend.put(baseUrl,user),
  list   : ()   => () => backend.get(baseUrl),
})
