import React from 'react'

import { Label } from 'sdc-i18n-react'

export const Days = ({businessDays,daysLeft,bookedDays}) =>
<tr className="text-right">
  <th className="text-center"><Label value="planning.days" /></th>
  <td className="pr-3">{businessDays}</td>
  <td className="pr-3">{daysLeft > 0 && daysLeft}</td>
  <td className="pr-3">{bookedDays > 0 && bookedDays}</td>
</tr>
