import React from 'react'
import { inject, observer } from 'mobx-react'

// import AlertHelp from '../components/AlertHelp'
import { AlertHint    } from '../components/AlertHint'
import { ReleaseNotes } from '../bo/messages/ReleaseNotes'

// import { Link } from 'react-router-dom'

// import styled from 'styled-components'
// import { MARGIN_LARGE } from '../design/margins'

// import BookAd      from '../ads/amazon/BookAd'
// import { Gallery } from './Gallery'

// import { loginURL }    from '../user/login/urls'

// const Wrapper = styled.div`
//   ${MARGIN_LARGE}
//`

export const Home = inject('messagesStore')(observer(({messagesStore}) => {
  messagesStore.loadCategory('RN')
  return (<div className="row">
    <div className="col-md-4">
      <h3>Hinweise</h3>
      <p>Aktuell liegen keine besonderen Hinweise vor.</p>
    </div>
    <div className="col-md-4">
      <h3>Zu erledigen</h3>
      <AlertHint style={{fontSize: '200%', lineHeight: '1.2em'}}>Bitte pflegen Sie nach dem Einloggen Ihre Stammdaten unter [<i className="fa fa-cog" /> (Benutzername)]</AlertHint>
    </div>
    <div className="col-md-4">
      <h3>Neuerungen</h3>
      <ReleaseNotes />
    </div>
  </div>)
}))


//     <Wrapper>
//       <BookAd />
//       <h1 style={{textAlign: 'center'}}>Get new insights into your Scrivener projects!</h1>
//       <div style={{marginRight: '150px'}}><Gallery /></div>
//       <div>
//         <h4 style={{marginRight: '150px', marginTop: '40px', textAlign: 'center'}}>
//           <Link className="btn btn-default" to={loginURL}
//           >Sign up now to get started!</Link>
//         </h4>
//       </div>
//       <div className="media" style={{margin: '24px', paddingTop: '24px', borderTop: 'solid 3px #cef'}}>
//         <div className="media-left media-top">
//           <img className="media-object" src="/project-wizard.png" alt="Create Project Wizard"
//             style={{boxShadow: '0 -4px -4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}/>
//         </div>
//         <div className="media-body" style={{padding: '24px'}}>
//           <h2 className="media-heading">Get all set in three easy steps</h2>
//           <ul style={{fontSize: '150%'}}>
//             <li>Sign up with your Google account
//               <div style={{color: '#999'}}>More login options coming soon - please let us know which of your existing accounts you would like to use!</div>
//             </li>
//             <li>Create a project by entering a working title
//               <div style={{color: '#999'}}>We will use your email address and the working title to set up a shared folder on Dropbox.</div>
//             </li>
//             <li>Move your existing Scrivener project into this shared folder.
//               <div style={{color: '#999'}}>We will not share or publish your intellectual property without your explicit consent.</div>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </Wrapper>
//   )
// }
