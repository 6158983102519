import React from 'react'
import { Provider, inject, observer } from 'mobx-react'

import { AlertHelp       } from '../components/AlertHelp'
import { PanelHeading    } from '../components/panels/heading'

import { DaysList        } from './days/DaysList'
import { DayDetails      } from './days/DayDetails'

import { BinariesList    } from '../crm/binaries/BinariesList'
import { BinaryDetails   } from '../crm/binaries/BinaryDetails'
import { LogoUploadPanel } from '../crm/binaries/LogoUploadPanel'

import { CompaniesPanel  } from '../crm/companies/panel'

import { PostalsPanel    } from '../crm/postal'

import { DetailsTable    } from '../components/DetailsTable'
import { DetailsTextEdit } from '../components/DetailsTextEdit'
import { DetailsTimeEdit } from '../components/DetailsTimeEdit'


@inject('postalStore','binariesStore','companiesStore','settingsStore')
@observer
class UserPanel extends React.Component {
  render() {
    const store = this.props.settingsStore
    const settings = {
      store,
      update: store.save,
    }
    return (<div className="row">
      <AlertHelp className="col-md-12">
        Hier können Sie Ihre persönlichen Informationen hinterlegen und pflegen, die z.B. auf Timesheets oder Rechnungen ausgewiesen werden.
      </AlertHelp>
      <div className="col-md-3">
        <DaysList creatable />
        <DayDetails />
      </div>
      <div className="col-md-3">
        <PostalsPanel />
        <CompaniesPanel />
      </div>
      <div className="col-md-3">
        {!this.props.binariesStore.selected.id && <AlertHelp>Wählen Sie eine Datei durch Anklicken aus, um die Details zu bearbeiten.</AlertHelp>}
        {this.props.binariesStore.selected.id && <hr/>}
        {this.props.binariesStore.selected.id && <h4>Details zu Datei '{this.props.binariesStore.selected.name}'</h4>}
        {this.props.binariesStore.selected.id && <BinaryDetails/>}
        <LogoUploadPanel titel="Datei hochladen" onDrop={this.props.binariesStore.addBinary} />
      </div>
      <div className="col-md-3">
        <PanelHeading value="settings.heading" topic="settings.intro" icon="cogs" />
        <Provider {...settings}><DetailsTable>
          <colgroup>
            <col width="66%" />
            <col width="33%" />
          </colgroup>
          <tbody>
            <DetailsTextEdit label="Schrittgröße im Kalender" field="step" defaultValue={10} />
            <DetailsTextEdit label="Schritte im Kalender" field="timeslots" defaultValue={3} />
            <DetailsTimeEdit label="Arbeitszeit pro Tag" field="dailyTime" defaultValue={540} />
            <DetailsTimeEdit label="Arbeitszeit Start im Kalender" field="startTime" defaultValue={360} />
            <DetailsTimeEdit label="Arbeitszeit Ende im Kalender" field="stopTime" defaultValue={1080} />
          </tbody>
        </DetailsTable></Provider>
      </div>
      <div className="col-md-6">
        <h4>Dateien &amp; Logos</h4>
        <BinariesList />
      </div>
      <div className="col-md-6">
      </div>
    </div>)
  }
}

export { UserPanel }
