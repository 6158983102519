import { autorun } from 'mobx'

import { StandardStore, Patching } from 'sdc-mobx-stores'
import { userStore     } from 'sdc-auth-user'

export class AuthenticatedStore extends Patching(StandardStore) {

  constructor(options) {
    super(options)
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
      } else {
        this.clearEntries()
      }
    })
  }

  reload = () => {
    this.list({})()
  }

}
