import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'
import { PrintButton  } from 'sdc-react-basics'

import { urlBase } from '../../env/constants'


export const Header = inject('contractsStore','monthStore')(observer(({contractsStore,monthStore,booked}) =>
<tr>
  <th style={{color: '#999'}}><Label value="stats.planning" /></th>
  <th>geplant</th>
  {!booked && <th>Std-Satz</th>}
  {booked > 0 && <th>offen</th>}
  <th>
    <span>gebucht </span>
    {booked > 0 && <PrintButton title="month.print" href={`${urlBase}invoice/${contractsStore.selected.id}/${monthStore.month.num}.pdf`} target="_blank" />}
  </th>
</tr>))
