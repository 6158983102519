import React from 'react'
import { inject, observer } from 'mobx-react'

import moment from 'moment'

import { MarkdownPanel } from 'sdc-markdown-viewer'

import './ReleaseNotes.css'

@inject('messagesStore')
@observer
export class ReleaseNotes extends React.Component {
  render() {
    const store = this.props.messagesStore
    return <div>
      {store.releaseNotes.map(rn => {
        const text = '#### Update vom '+ moment(rn.published).format('DD. MMMM') +'\n\n'+rn.body
        return <MarkdownPanel key={rn.id} text={text} className="release-notes" />
    })}
    </div>
  }
}
