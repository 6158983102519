import { autorun, observable, action, reaction } from 'mobx'

import { StandardStore, async } from 'sdc-mobx-stores'
import { userStore     } from 'sdc-auth-user'

import { api } from './api'

export class MessagesStore extends StandardStore {

  @observable snapshot = {
    body: ''
  }
  @observable releaseNotes = []
  @observable messages = {}

  constructor({
    api,
  }) {
    super({
      listName  : 'messages',
      entryName : 'message',
      api,
    })
    autorun(() => {
      if (userStore.user.admin) {
        this.list({})()
      }
    })
    reaction(() => (this.selected.id), (id) => {
      if (this.snapshot.id !== id) {
        this.snapshot = {
          id: id,
          body: this.selected.body || '',
        }
      }
    })
    reaction(() => (this.snapshot.body), (body) => {
      if (this.snapshot.id === this.selected.id && this.snapshot.body !== this.selected.body) {
        this.update({
          ...this.selected,
          body : body.trim(),
        })
      }
    }, {
      delay: 1500,
    })
  }

  loadCategory = cat => {
    this.list(cat || 'RN',this.setReleaseNotes)()
  }

  load = (cat,key,callback) => {
    callback({loading: true})
    async(this.api.load({cat,key})).then(this.setMessage(callback))
  }
  loading = (category,key) => this.messages[category] === undefined || this.messages[category][key] === undefined || this.messages[category][key].loading
  message = (category,key) => this.loading(category,key) === false ? this.messages[category][key].text : undefined

  @action
  updateSnapshot = body => {
    this.snapshot.body = body
  }
  updateBody = e => {
    const value = e.target.value
    this.updateSnapshot(value)
  }

  @action
  setPending = (cat,key) => {
    this.messages = {
      ...this.messages,
      [cat]: {
        ...(this.messages[cat] || {}),
        [key]: {loading: true}
      }
    }
  }
  setMessage = callback => payload => {
    callback({text: payload.message.body})
  }

  setReleaseNotes = deferred => action(payload => {
    this.releaseNotes = payload.messages
    deferred.resolve(this.releaseNotes)
  })
}

export const makeMessagesStore = ({backend,...options}) => new MessagesStore({...options,api : api({backend})})
