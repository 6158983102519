import React from 'react'
import { inject, observer } from 'mobx-react'

import { AlertHint       } from 'sdc-cms-help'
import { PanelHeading    } from '../../components/panels/heading'

import { ContractsList   } from './list'
import { ContractDetails } from './details'


@inject('customerStore')
@observer
export class ContractsPanel extends React.Component {

  render() {
    const store = this.props.customerStore
    const customerID = store.selected.id
    return <span>
      {!customerID && <PanelHeading value="contracts.heading.common" topic="contracts.intro" icon="tasks" />}
      {!customerID && <AlertHint topic="customer.hint.select" />}

      {customerID && <PanelHeading value="contracts.heading.customer" label={store.selected.name} topic="contracts.intro" icon="tasks" />}
      {customerID && <ContractsList filter={c => c.customerID === customerID} />}
      <ContractDetails/>
    </span>
  }
}
