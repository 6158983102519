import React from 'react'

import { Table } from 'reactstrap'

import { Issues   } from '../issues'
import { Planning } from '../planning'

export const MonthlyStats = () =>
<Table bordered responsive size="sm" className="text-center">
  <tbody>
    <Issues />
    <Planning />
  </tbody>
</Table>
