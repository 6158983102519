import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React    from 'react'
import ReactDOM from 'react-dom'

import { AppContainer } from 'react-hot-loader'

import { autorun, configure } from 'mobx'
import { Provider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { Router } from 'react-router'
import createHistory from 'history/createBrowserHistory'

import './custom.scss'
import 'font-awesome/css/font-awesome.css'
import './index.css'

import { userStore } from 'sdc-auth-user'

import { App } from './app'

import { uiBase }   from './env/constants'

//import './ads/tracking'

import * as otherStores from './index-stores'

import { unregister } from './registerServiceWorker'

configure({
  enforceActions : 'always',
})

const baseHistory = createHistory()
const routingStore = new RouterStore()

const appState = otherStores.appState

/*eslint-disable */
// const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
/*eslint-enable */

// const enhancer = composeSetup(
//   // Middleware you want to use in development:
//   // applyMiddleware(sagaMiddleware),
//   // applyMiddleware(routingMiddleware),
//   persistState(['routing','client','dummy','appState.customers'])
//   //DevTools.instrument()
// )

export const history = syncHistoryWithStore(baseHistory, routingStore)

// const sendPageView = (view) => {
//   if (process.env.NODE_ENV === 'production') {
//     /* global gtag */
//     gtag('config', 'UA-36546131-3', {
//       page_path : view,
//     })
//     gtag('event','screen_view', {
//       screen_name : view,
//     })
//   }
// }

// history.listen((location,action) => {
//   sendPageView(location.pathname)
// })

const stores = {
  routing: routingStore,
  ...otherStores
}

let authenticated = false

autorun(() => {
  if (userStore.state.authenticated && !authenticated) {
    console.log(`forwarding to EM, user = ${userStore.user.name}`)
    history.push('em')
  }
  authenticated = userStore.state.authenticated
})

//subscribeActors(store)

const render = Component =>
ReactDOM.render(
  <AppContainer>
    <Provider {...stores} >
      <Router history={history} path={uiBase} >
        <Component />
      </Router>
    </Provider>
  </AppContainer>,
  document.getElementById('root')
)

render(App)

if (module.hot) {
  module.hot.accept('./app', () => render(App))
}

unregister()
//registerServiceWorker(appState.setUpdateAvailable)
