import { requiredParam } from 'sdc-utilities'

const baseUrl = 'sheets'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  create : sheet     => () => backend.post(baseUrl,sheet),
  patch  : id        => () => backend.patch(baseUrl+'/'+id),
  update : sheet     => () => backend.put(baseUrl,sheet),
  remove : sheet     => () => backend.delete(baseUrl+'/'+sheet.id),
  list   : ({month}) => () => backend.get(baseUrl+'/'+month),
  select : sheet     => () => backend.post(baseUrl+'/'+(sheet ? sheet.id : null)),
})
