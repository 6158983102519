import React from 'react'

import { Label } from 'sdc-i18n-react'

import './index.scss'

export const AboutPrivacy = () => <div className="about bordered-panel">

<h4><Label value="about.sb" /> :: <Label value="about.privacy" /></h4>

<p className="large">ScrumBill is founded on the principle of helping people log and report times they work on projects.
We know that you care about how your personal information is used and shared, and we take your privacy very
seriously. By visiting the ScrumBill website, you are accepting the practices outlined in this policy.</p>

<p>This Privacy Policy covers ScrumBill's treatment of personal information that ScrumBill gathers when you are on the
ScrumBill website and when you use ScrumBill services. This policy does not apply to the practices of third parties that
ScrumBill does not own or control, or to individuals that ScrumBill does not employ or manage.</p>

<div className="section clearfix">
<div className="legal"><h6>Information collected by ScrumBill</h6>
<p>We only collect personal information that is relevant to the purpose of our website.
This information allows us to provide you with a customized and efficient experience.
We collect the following types of information from our ScrumBill users:</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>We collect information to make ScrumBill useful to you.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Information You Provide to Us:</h6>
<ol>
<li>We receive and store any information you enter on our website or
provide to us in any other way. You can choose not to provide us with
certain information, but then you may not be able to take advantage of
many of our special features.</li>
<li>Registration: In order for you to use ScrumBill services you must complete a registration form.
As part of this registration form, we require select personal information.</li>
<li>User Profile: We are using Gravatar to look up
your photo. </li>
<li><b>Automatic Information:</b><ol>
<li>We receive and store certain types of information whenever you interact with us.
ScrumBill  and its authorized agents automatically receive and record certain "traffic data" on their server logs
from your browser including your IP address, ScrumBill cookie information, and the page you requested.
ScrumBill uses this traffic data to help diagnose problems with its servers,
analyze trends and administer the website.</li>
<li>ScrumBill may collect and, on any page, display the total counts that page has been viewed.
This includes User Profile pages.</li>
<li>Many companies offer programs that help you to visit websites anonymously.
While ScrumBill will not be able to provide you with a personalized experience
if we cannot recognize you, we want you to be aware that these programs are available.</li>
</ol></li>
</ol>
</div>
<div className="gist"><h6>Basically,</h6>
<p>We collect your registration and user profile data.
Our servers also collect log information used to make the website faster and better.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>E-mail Communications</h6>
<p>ScrumBill is very concerned about your privacy and we will never provide your email address
to a third party without your explicit permission, as detailed in the "Sharing Your Information"
section below. ScrumBill may send out e-mails with ScrumBill-related news, products, offers, surveys or promotions.
You may also receive notification e-mails from ScrumBill, which inform you of actions (e.g. friend requests)
that have been performed on the site. If you do not want to receive e-mail from us,
please visit the Email Preferences section of your User Profile and/or follow the instructions contained
in the unwanted e-mail message. However, please note that in all cases you will continue to receive
all system e-mails (e.g. those regarding forgotten user passwords) and legal notices
(e.g. updates to our policies) from us.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>We will send you emails based on your profile settings.
From time to time, we may send emails to all our users.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Cookies</h6>
<p>Cookies are alphanumeric identifiers that we transfer to your computer's hard drive
through your Web browser to enable our systems to recognize your browser and tell us
how and when pages in our website are visited and by how many people.
ScrumBill cookies do not collect personal information, and we do not combine information
collected through cookies with other personal information to tell us who you are or
what your screen name or e-mail address is.</p>
<p>The "help" portion of the toolbar on the majority of browsers will direct you
on how to prevent your browser from accepting new cookies, how to command the browser
to tell you when you receive a new cookie, or how to fully disable cookies.
We recommend that you leave the cookies activated because cookies allow you to use some
of ScrumBill's coolest features. Some of our business partners, like our advertisers,
may use cookies on our website. We have no access to, or control over, these cookies.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>To remember you, our system will give you a cookie. It's safe.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Sharing Your Information</h6>
<p>Because ScrumBill enables people to discover and share information with one another,
information about the people who use ScrumBill is an integral part of the ScrumBill experience.
Rest assured that we neither rent nor sell your personal information to anyone and
that we will share your personal information only as described below.</p>
<p><b>ScrumBill Personnel:</b> ScrumBill personnel and authorized consultants and/or
contractors may have access to user information if necessary in the normal course of ScrumBill business.</p>
<p><b>Business Transfers:</b> In some cases, we may choose to buy or sell assets.
In these types of transactions, user information is typically one of the business assets that is transferred.
Moreover, if ScrumBill, or substantially all of its assets, were acquired,
user information would be one of the assets that is transferred.</p>
<p><b>Protection of ScrumBill and Others:</b> We may release personal information
when we believe in good faith that release is necessary to comply with a law;
to enforce or apply our Terms of Use and other policies;
or to protect the rights, property, or safety of ScrumBill,
our employees, our users, or others. This includes exchanging information
with other companies and organizations for fraud protection and credit risk reduction.</p>
<p><b>User Profile information:</b> Information collected during registration
and subsequent visits, such as your full name and location,
may be displayed to other ScrumBill users in your User Profile.
The User Profile is the area on ScrumBill where we allow you to tell
other ScrumBill users about yourself. You may control what personal
information is displayed in your User Profile by visiting the Settings section of your User Profile.</p>
<p><b>Followers' Activity:</b> Much like other online social networks,
ScrumBill allows non-followers to browse your followers activity.</p>
<p><b>Syndication:</b> ScrumBill allows for the RSS syndication of all of
its public content within the ScrumBill website.</p>
<p><b>With Your Consent:</b> Except as noted above, we will contact you when your personal information
is shared with third parties or used for a purpose incompatible with the purpose(s) for which it was
originally collected, and you will be able to opt out to prevent the sharing of this information.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>We do not sell your information to anyone. However, we will show publicly available
information to other users and may release other information to authorities where required by law.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Keeping Information Secure</h6>
<p>Your ScrumBill account information is protected by a password for your privacy and security. Protect
against unauthorized access to your password and to your computer by logging off once you have finished
using a shared computer.</p>
<p>Only employees who need personal information to perform a specific job (for example, a customer service
representative) are granted access to it. All of our employees are kept up to date on our privacy and
security practices.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Your account is protected by an encrypted password. We keep it secure, you must also keep it safe.
Our employees may not access your account unless required to do a specific job.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Ways to Control Display and Use of Your Information</h6>
<p>As stated previously, you can always opt not to disclose information, but then you may be unable to
use certain features on our website.</p>
<p>By visiting your User Profile, you can correct, amend, add or delete personal information on our website.</p>
<p>If you do not wish to receive e-mail from us or other ScrumBill users, please visit the Settings of your User Profile
and/or follow the instructions contained in the unwanted e-mail message.</p>
<p>However, please note that you will continue to receive all system e-mails (e.g. those regarding
forgotten user passwords) and legal notices (e.g. updates to our policies) from us.</p>
<p>As explained earlier, the "help" portion of the toolbar on the majority of browsers will direct you on how
to prevent your browser from accepting new cookies, how to command the browser to tell you when you receive a
new cookie, or how to fully disable cookies. Please note, however, that if your browser does not accept cookies,
you will not be able to take advantage of some of our attractive features.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>You can choose not to share information with us, but your experience of the site may be limited.
However, until your account is deleted, you may receive all system emails.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Children Under 18 Years of Age</h6>
<p>You must be 14 years and older to register to use the ScrumBill website. As a result,
ScrumBill does not specifically collect information about children. If we learn that ScrumBill has collected
information from a child under the age of 13, we will delete that information as quickly as possible.
We recommend that minors between the ages of 14 and 18 ask and receive their parents' permission
before using ScrumBill or sending information about themselves or anyone else over the Internet.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Please register only when you reach 18 years old,
or ask your parents' permission if you are between 14 and 18 years old.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Changes to this Privacy Policy</h6>
<p>ScrumBill may amend this Privacy Policy from time to time, at its sole discretion.
Use of information we collect now is subject to the Privacy Policy in effect at the
time such information is used. If we make changes to the Privacy Policy,
we will notify you by posting an announcement on the ScrumBill website so you are
always aware of what information we collect, how we use it,
and under what circumstances if any, it is disclosed.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Privacy policy may change.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Conditions of Use</h6>
<p>If you decide to visit the ScrumBill website, your visit and any possible dispute over
privacy is subject to this Privacy Policy and our <a href="/terms">Terms of Use</a>,
including limitations on damages, arbitration of disputes, and application of German law.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Visiting ScrumBill is subject to Terms and Privacy policy.</p>
</div>
</div>

<div className="section clearfix">
<div className="legal"><h6>Effective Date of this Privacy Policy</h6>
<p>This Privacy Policy is effective as of December 7, 2012.</p>
</div>
<div className="gist"><h6>Basically,</h6>
<p>Effective as of December 1, 2017.</p>
</div>
</div>

</div>
