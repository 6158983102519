import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { EntryFieldsList  } from 'sdc-cms-editing'

export const HolidayDetails = inject('holidaysStore')(observer(({holidaysStore}) => {
  const data = {
    store     : holidaysStore,
    dataStore : holidaysStore,
    update    : holidaysStore.update,
  }

  return <Provider {...data}>
    <EntryFieldsList type="Feiertage" fields={['name','date']} />
  </Provider>
}))
