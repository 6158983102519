import { action, observable } from 'mobx'

import { publish, subscribe } from 'sdc-publish-subscribe'

import { AuthenticatedStore } from '../../data/AuthenticatedStore'

import { api } from './api'
import { customerToOption   } from '.'


export class CustomerStore extends AuthenticatedStore {

  @observable options = []

  constructor({
    ...options
  }) {
    super({
      ...options,
      listName  : 'customers',
      entryName : 'customer',
    })
    subscribe('contract-selected', contract => {
      if (contract?.customer && (this.selected.id !== contract.customer.id)) {
        this.select(contract.customer)()
      }
    })
  }

  reload = () => {
    this.list({
      callback : this.parseContracts,
    })()
  }

  parseContracts = deferred => payload => {
    this.setEntries(deferred)(payload)
    this.afterUpdate()
  }

  @action
  parseOptions = () => {
    this.options = this.dataList.filter(customer => !customer.locked).map(customerToOption)
  }

  afterSelect = customer => {
    publish('customer-selected', customer.id)
  }

  @action
  assign = assignment => {
    this.selected.contractorID = assignment.value
    this.update(this.selected)
  }

  @action
  postal = assignment => {
    this.selected.postalID = assignment.value
    this.update(this.selected)
  }

  beforeUpdate = (customer,field,value) => {
    if (field === 'locked' && value) {
      publish('customer-locked', customer)
    }
  }
  afterUpdate = () => {
    this.parseOptions()
  }
}

export const makeCustomerStore = ({backend}) => new CustomerStore({api : api({backend})})
