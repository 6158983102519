import React        from 'react'
import { observer } from 'mobx-react'

import { PanelHeading  } from '../components/panels/heading'

import { PartnersPanel } from './partners/panel'

import { TextsList     } from './texte/list'
import { TextsPanel    } from './texte/panel'
import { TextPreview   } from './texte/preview'

import { PostalsPanel  } from '../crm/postal'


export const LettersPanel = observer(() =>
<div className="row">
  <div className="col-md-3">
    <PostalsPanel />
    <PartnersPanel />
  </div>
  <div className="col-md-3">
    <PanelHeading value="messages.heading" topic="messages.intro" icon="envelope" />
    <TextsList />
  </div>
  <TextsPanel    className="col-md-3" />
  <TextPreview   className="col-md-3" />
</div>)
