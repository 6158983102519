import axios  from 'axios'

import { makeTypesStore, makeControlsStore, makeHelpStore, makeIndexStore }  from 'sdc-cms-client'
import { makeCmsLanguagesStore, makeCmsLabelsStore } from 'sdc-i18n-cms'
import { languagesStore, labelsStore } from 'sdc-i18n-stores'
import { makeAuthStore      } from 'sdc-auth-store'

import { makeDaysStore      } from './user/days/store'
import { makeMonthsStore    } from './user/months/store'

import { makeEditingStore   } from 'sdc-cms-writing'

import { makeAccountsStore  } from './bo/accounts/store'
import { makeMessagesStore  } from './bo/messages/store'
import { makeSettingsStore  } from './data/settings/store'

import { makeBinariesStore  } from './crm/binaries/store'
import { makeCustomerStore  } from './crm/customers/store'
import { makeContractsStore } from './crm/contracts/store'
import { makeCompaniesStore } from './crm/companies/store'
import { makePostalStore    } from './crm/postal/store'

import { makeMonthStore     } from './data/month/store'
import { makeHolidaysStore  } from './bo/holidays/store'

import   trackingStore        from './tracking/store'
import { makePacketsStore   } from './tracking/packets/store'
import { makeSheetsStore    } from './tracking/sheets/store'
import { makeWorkDoneStore  } from './tracking/workDone/store'

import { makePartnersStore  } from './letters/partners/store'
import { makeTextsStore     } from './letters/texte/store'

import { spaceID, messagesType, apiBase } from './env/constants'

export { userStore } from 'sdc-auth-user'

export { appState  } from './app/state'

const cmsBase        = 'https://www.headless-cms.io/api/'
const cms = axios.create({
  withCredentials : true,
  baseURL         : cmsBase,
})
// cms.interceptors.request.use(config => {
//   config.headers['X-my-funny-header'] = 'Hello, world!'
//   console.log('cms config',config)
//   return config
// })
const api = axios.create({
  withCredentials : true,
  baseURL         : apiBase,
})

export const authStore      = makeAuthStore(    {backend : api})

export const cmsLanguagesStore = makeCmsLanguagesStore({backend : cms, spaceID})
const typesStore     = makeTypesStore(    {backend : cms})
const editingStore   = makeEditingStore(  {backend : cms,typesStore})
const indexStore     = makeIndexStore(    {backend : cms,typesStore,editingStore})
const controlsStore  = makeControlsStore( {backend : cms})
const cmsLabelsStore = makeCmsLabelsStore({backend : cms})
const helpStore      = makeHelpStore(     {backend : cms,labelsStore})

export const accountsStore = makeAccountsStore({backend : api})

const monthStore     = makeMonthStore(    {backend : api, labelsStore})
const daysStore      = makeDaysStore(     {backend : cms,typesStore,editingStore})
const holidaysStore  = makeHolidaysStore( {backend : cms,typesStore,editingStore})

const sheetsStore    = makeSheetsStore(   {backend : api, monthStore})
const packetsStore   = makePacketsStore(  {backend : api, monthStore,sheetsStore})
const workDoneStore  = makeWorkDoneStore( {backend : api, monthStore,sheetsStore,packetsStore,daysStore,holidaysStore})
sheetsStore.packetsStore = packetsStore

export const binariesStore  = makeBinariesStore( {backend : api})
export const companiesStore = makeCompaniesStore({backend : api, binariesStore})
export const customerStore  = makeCustomerStore( {backend : api})
export const contractsStore = makeContractsStore({backend : api})
export const postalStore    = makePostalStore(   {backend : api})
packetsStore.contractsStore = contractsStore
export const settingsStore  = makeSettingsStore( {backend : api})

const monthsStore    = makeMonthsStore(  {backend : cms,typesStore,editingStore,contractsStore,daysStore,monthStore,holidaysStore,workDoneStore,sheetsStore})

export const messagesStore = makeMessagesStore({backend : api})

labelsStore.addTranslation('en.action.login','Login')
labelsStore.addTranslation('de.action.login','Anmelden')

typesStore.setSpaceID(spaceID)

cmsLabelsStore.setTypeID(messagesType)
helpStore.setTypeID('4U1DCMqoxopTHSsUEabg27pGFBCNU7Ls')

languagesStore.selectLanguage('de')()

//controlsStore.setTypeID('xtHdLPwkmyO3pkiAJQXhMqM5m9ol9az8')

export {
  daysStore,
  monthsStore,

  cmsLabelsStore,

  helpStore,
  labelsStore,
  languagesStore,
  controlsStore,
  typesStore,
  editingStore,
  indexStore,

  monthStore,
  holidaysStore,

  trackingStore,
  packetsStore,
  sheetsStore,
  workDoneStore,
}

export const partnersStore = makePartnersStore({backend : cms,typesStore,indexStore,editingStore,authStore})
export const textsStore    =    makeTextsStore({backend : cms,typesStore,indexStore,editingStore,authStore})
