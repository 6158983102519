import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label            } from 'sdc-i18n-react'

import { AlertHelp        } from '../../components/AlertHelp'
import { AlertHint        } from '../../components/AlertHint'
import { PanelHeading     } from '../../components/panels/heading'

import { SheetsList       } from './list'
import { SheetDetails     } from './details'

import { MonthSelector    } from '../../components/MonthSelector'


@inject('packetsStore','sheetsStore')
@observer
export class SheetsPanel extends React.Component {

  render() {
    const packetID = this.props.packetsStore.selected.id
    const {creatable: details,sheetsStore: store} = this.props
    const selected = store.selected.id

    return <span>
      {!packetID && <PanelHeading value="sheets.heading.common" topic="sheets.intro" icon="file-o" />}
      {packetID && <PanelHeading value="sheets.heading.packet" topic="sheets.intro" label={this.props.packetsStore.selected.name} icon="file-o" />}
      <MonthSelector selectable />
      {packetID && <SheetsList {...this.props} filter={s => s.packet.id === packetID} />}
      {details && !packetID && <AlertHint><Label value="packet.hint.select" /></AlertHint>}
      {details && packetID && !selected && <AlertHelp><Label value="sheet.help.select" /></AlertHelp>}
      {details && selected && <hr/>}
      {details && selected && <h4><Label value="sheet.details.heading" label={store.selected.name} /></h4>}
      {details && selected && <SheetDetails/>}
    </span>
  }
}
