import React from 'react'
import { inject, observer } from 'mobx-react'

import { Editor } from '../Editor'

export const TextCellEditor = ({cell,update}) => (
  <Editor contentEditable suppressContentEditableWarning
    onFocus={(e) => {
      window.getSelection().selectAllChildren(e.target)
    }}
    onBlur={(e) => {
      const field = cell.column.id
      const value = e.target.textContent
      update({
        ...cell.original,
        [field] : value,
      })
    }}
  >{cell.value}</Editor>
)

@inject('store','update')
@observer
export class CheckboxFieldEditor extends React.Component {
  render() {
    return <input type="checkbox" checked={this.props.store.selected[this.props.field]}
      onChange={(e) => {
        const value = e.target.checked
        this.props.store.updateField(this.props.field,value)
      }}
    />
  }
}
