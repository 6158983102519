import { action, observable, toJS } from 'mobx'

export class DeleteState {
  @observable opened = {}
  callback = null
  @action open = (tag,callback) => {
    this.callback    = callback
    this.opened = {
      ...this.opened,
      [tag] : true,
    }
    console.log(JSON.stringify(toJS(this.opened)))
  }
  cancel = tag => action(e => {
    this.opened = {
      ...this.opened,
      [tag] : false,
    }
  })
  proceed = tag => action(e => {
    this.cancel(tag)(e)
    this.callback(e)
  })
}

export const state = new DeleteState()
