import React from 'react'
import { inject, observer } from 'mobx-react'

import moment from 'moment';

import { Editor } from '../Editor'

const format = (time) => {
  const h = Math.floor(time / 60)
  const m = ('0'+(time % 60)).slice(-2)
  return h +':'+ m
}
export const toMoment = (time) => moment('0:00','HH:mm').minutes(time || 0)

export const toDate = (date,time) => {
  const h = Math.floor(time / 60)
  const m = ('0'+(time % 60)).slice(-2)
  return moment(date).hours(h).minutes(m).toDate()
}
export const fromDate = (date) => {
  const mom = moment(date)
  return 60 * mom.hours() + mom.minutes()
}
export const parse = (value) => {
  if (value.indexOf(':') > 0) {
    const parts = value.split(':')
    return 60 * parseInt(parts[0],10) + parseInt(parts[1],10)
  } else if (value.length < 3)
    return value * 60
  else
    return 60 * Math.floor(value / 100) + (value % 100)
}

export const TimeFormatter = ({time,inline}) => (
  (time) ? (
    (inline) ?
    <span>{format(time)}</span> :
    <div>{format(time)}</div>
  ) : null
)
export const TimeCellEditor = ({cell,update}) => (
  <Editor contentEditable suppressContentEditableWarning
    onFocus={(e) => {
      window.getSelection().selectAllChildren(e.target)
    }}
      onBlur={(e) => {
        const field = cell.column.id
        const value = parse(e.target.textContent)

        update({
          ...cell.original,
          [field] : value,
        })
      }}
  >{cell.value && format(cell.value)}</Editor>
)

@inject('store','update')
@observer
export class TimeFieldEditor extends React.Component {
  render() {
    const current = this.props.store.selected[this.props.field] || this.props.defaultValue
    return <Editor contentEditable suppressContentEditableWarning
      onFocus={(e) => {
        window.getSelection().selectAllChildren(e.target)
      }}
      onBlur={(e) => {
        const value = parse(e.target.textContent)
        this.props.update({
          ...this.props.store.selected,
          [this.props.field] : value,
        })
      }}
    >{current && format(current)}</Editor>
  }
}
