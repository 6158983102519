import { action, observable } from 'mobx'

import _ from 'lodash'

import { publish, subscribe } from 'sdc-publish-subscribe'

import { AuthenticatedStore } from '../../data/AuthenticatedStore'

import { api                } from './api'
import { contractToOption   } from '.'


export class ContractsStore extends AuthenticatedStore {

  @observable contracts = {}
  @observable options   = []

  constructor(options) {
    super({
      ...options,
      listName  : 'contracts',
      entryName : 'contract',
    })

    subscribe('customer-locked', customer => {
      if (customer?.id) {
        console.log('locking contracts of customer '+ customer.name)
        this.lock(customer.id)
      }
    })
  }

  reload = () => {
    this.list({
      callback : this.parseContracts,
    })()
  }

  parseContracts = deferred => action(payload => {
    this.setEntries(deferred)(payload)
    this.afterUpdate()
    this.contracts = _.keyBy(payload.contracts, 'id')
  })

  @action
  parseOptions = () => {
    this.options = this.dataList.filter(contract => !contract.locked).map(contractToOption)
  }

  @action
  assign = assignment => {
    this.selected.customerID = assignment.value
    this.update(this.selected)
  }

  afterSelect = contract => {
    publish('contract-selected', contract)
  }

  clone = contract => action(e => {
    const {id,size,rest,created,...cloned} = {...contract}
    this.origin = id
    this.create(cloned)()
  })

  origin = null

  afterCreate = newContract => {
    if (this.origin) {
      publish('contract-cloned', {
        oldContractID : this.origin,
        newContractID : newContract.id,
      })
      this.origin = null
    }
  }

  lock = action(customerID => {
    this.dataList.filter(contract => contract.customerID === customerID).forEach(contract => {
      console.log('locking contract '+ contract.name)
      contract.locked = true
      this.update(contract,'locked',true)
    })
  })
  beforeUpdate = (contract,field,value) => {
    if (field === 'locked' && value) {
      publish('contract-locked',contract)
    }
  }
  afterUpdate = () => {
    this.parseOptions()
  }
}

export const makeContractsStore = ({backend}) => new ContractsStore({api : api({backend})})
