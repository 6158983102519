import React from 'react'

import { Label } from 'sdc-i18n-react'

export const AboutLegal = () => <div className="bordered-panel">

<a href="http://www.sdc-software.com" target="_new"><img style={{float: 'right'}} alt="SDC homepage"
  src="http://www.sdc-software.com/resources/SDC-blu8b-120x80.gif" /></a>

<h4><Label value="about.sb" /> :: <Label value="about.legal" /></h4>

This site is operated by <a target="_new"
  href="http://www.sdc-software.com"><b>Software
Design & Consulting GmbH</b></a>, an IT consulting company based near Munich, Germany.

<h6>Postal address</h6>
Software Design & Consulting GmbH<br/>
Aufkirchner Straße 25a<br/>
<b>D-82069 Hohenschäftlarn</b><br/>
<p>GERMANY</p>

<h6>Points of Contact</h6>

Fon: +49 (8178) 54 18 25<br/>
Fax: +49 (8178) 54 18 40

<p>E-Mail: webmaster (at) sdc-software.com</p>

<p>Managing Director: Dr. Erich W. Schreiner</p>

<p>Main Office: Hohenschäftlarn</p>

<p>Trade Register: HRB 182640</p>

<p>VAT-ID: DE 270 037 667</p>

</div>
