import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider } from 'mobx-react'

import { SheetSelector   } from '../sheets/selector'
import { sheetToOption   } from '../sheets'

import { DeleteButton    } from '../../components/delete'

import { DetailsTable    } from '../../components/DetailsTable'
import { DetailsTextEdit } from '../../components/DetailsTextEdit'
import { DetailsDateEdit } from '../../components/DetailsDateEdit'
import { DetailsTimeEdit } from '../../components/DetailsTimeEdit'
import { DetailsTimeView } from '../../components/DetailsTimeView'

@inject('workDoneStore')
@observer
export class WorkDoneDetails extends React.Component {
  render() {
    const store = this.props.workDoneStore
    if (!store.selected.id)
      return null
    else {
      const data = {
        store,
        update : store.update,
      }
      return (<Provider {...data}><DetailsTable>
        <colgroup>
          <col width="33%" />
          <col width="66%" />
        </colgroup>
        <tbody>
          <tr>
            <th>Paket</th>
            <td><SheetSelector value={sheetToOption(store.selected.sheet)} onChange={store.assign(store.selected)} /></td>
          </tr>
          <DetailsDateEdit label="Datum" field="date" fmt="DD.MM.YYYY" />
          <DetailsTimeEdit label="von" field="start" />
          <DetailsTimeEdit label="bis" field="stop" />
          <DetailsTimeEdit label="Pause" field="pause" />
          <DetailsTimeView label="Zeit gesamt" field="time" />
          <DetailsTextEdit label="Beschreibung" field="name" />
          <tr>
            <th></th>
            <td><DeleteButton onClick={store.remove(store.selected)}
                style={{width: '100%', padding: '4px'}}
                caption="Eintrag löschen" className="btn btn-danger" /></td>
          </tr>
        </tbody>
      </DetailsTable></Provider>)
    }
  }
}
