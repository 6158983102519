import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import { dateCell, timeCell } from '../../components/columns'

import { CloneButton, ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

import { DataTable } from '../../components/DataTable'


@inject('customerStore','contractsStore')
@observer
export class ContractsList extends React.Component {
  render() {
    return <DataTable store={this.props.contractsStore} columns={this.columns(this.props)} {...this.props}
      defaultSorted={[{
        id : 'created',
        desc : true,
      }]}
    />
  }

  columns = ({narrow}) => {
    if (narrow)
      return [nameCol,tagCol,restCol,createdCol]
    else
      return [reloadTrash(this.props.contractsStore),nameCol,tagCol,budget,sizeCol,restCol,createdCol]
  }

}

const reloadTrash = (contractsStore) => ({
  Header: <span>
    <ReloadButton onClick={contractsStore.reload} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      {!cell.original.size && <DeleteButton onClick={contractsStore.remove(cell.original)} confirm={false} />}
      {cell.original.size > 0 && <CloneButton onClick={contractsStore.clone(cell.original)}/>}
    </span>
  )
})
const nameCol = {
  Header: <Label value="field.name" />,
  accessor: 'name',
  headerClassName: 'left',
}
const tagCol = {
  Header: <Label value="field.ident" />,
  accessor: 'tag',
  headerClassName: 'left',
  width: 160,
  resizable: false,
}
const budget = {
  Header: <Label value="field.budget" />,
  accessor: 'budget',
  className: 'right',
  width: 80,
  resizable: false,
  Cell : timeCell,
}
const sizeCol = {
  Header: <Label value="field.booked" />,
  accessor: 'size',
  className: 'right',
  width: 80,
  resizable: false,
  Cell : timeCell,
}
const restCol = {
  Header: <Label value="field.remaining" />,
  accessor: 'rest',
  className: 'right',
  width: 80,
  resizable: false,
  Cell : timeCell,
}
const createdCol = {
  Header: <Label value="field.created" />,
  accessor: 'created',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
}
