import React from 'react'
import { inject, observer } from 'mobx-react'

import { MonthRenderer } from '../../components/MonthRenderer'

import { Header } from './header'
import { Days   } from './days'
import { Hours  } from './hours'
import { Netto  } from './netto'
import { Brutto } from './brutto'

export const Empty = inject('monthsStore','settingsStore')(observer(({monthsStore,settingsStore}) => {
  const values = monthsStore.selected.values || {}
  const daily = settingsStore.selected.dailyTime || 540
  const soll = values.businessDays * daily
  return <React.Fragment>
    <tr><td colSpan="4" style={{backgroundColor: '#fec'}}>Noch keine Stunden gebucht im <MonthRenderer /></td></tr>
    <tr style={{height: '4px'}}></tr>
    <Header booked={values.bookedTime} />
    <Days {...values} />
    <Hours {...values} daysLeft={values.businessDays} soll={soll} />
    <Netto  soll={soll} />
    <Brutto soll={soll} />
  </React.Fragment>
}))
