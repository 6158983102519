import React from 'react'
import { inject, observer } from 'mobx-react'

import moment from 'moment'

import { Editor } from '../Editor'

export const DateFormatter = ({date,fmt}) => {
  return (date) ? (
    <div>{moment(date).format(fmt || 'DD.MM.YY')}</div>
  ) : null
}

const parse = (value,month) => {
  if (value.indexOf('.') < 0) {
    value = ('0'+value).slice(-2)
    return moment.utc(month+value, 'YYYYMMDD')
  } else
    return moment.utc(value, 'DD.MM.YYYY')
}

export const DateCellEditor = ({cell,update,fmt}) => (
  <Editor contentEditable suppressContentEditableWarning
    onFocus={(e) => {
      window.getSelection().selectAllChildren(e.target)
    }}
    onBlur={(e) => {
      const field = cell.column.id
      const value = e.target.textContent
      const month = cell.original.month

      const date = parse(value,month)

      update({
        ...cell.original,
        [field] : date.valueOf(),
      })
    }}
  >{cell.value && moment(cell.value).format(fmt || 'DD.MM.YY')}</Editor>
)
@inject('store','update','monthStore')
@observer
export class DateFieldEditor extends React.Component {
  render() {
    return <Editor contentEditable suppressContentEditableWarning
      onFocus={(e) => {
        window.getSelection().selectAllChildren(e.target)
      }}
      onBlur={(e) => {
        const value = e.target.textContent
        const month = this.props.monthStore.month.num

        const date = parse(value,month)

        this.props.update({
          ...this.props.store.selected,
          [this.props.field] : date.valueOf(),
        })
      }}
    >{this.props.store.selected[this.props.field] && moment(this.props.store.selected[this.props.field]).format(this.props.fmt || 'DD.MM.YY')}</Editor>
  }
}
