import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label           } from 'sdc-i18n-react'

import { AlertHelp       } from '../../components/AlertHelp'
import { PanelHeading    } from '../../components/panels/heading'

import { CustomerDetails } from './details'

export const Customer = inject('customerStore')(observer(({customerStore}) => {
  const {id,name} = customerStore.selected
  return id ? <React.Fragment>
    <hr/>
    <PanelHeading value="customer.details.heading" label={name} icon="user" />
    <CustomerDetails/>
  </React.Fragment> :
  <AlertHelp><Label value="customer.help.select" /></AlertHelp>
}))
