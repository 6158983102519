import { action, autorun, toJS } from 'mobx'
import { ContentApi    } from 'sdc-cms-client'
import { UpdatingStore } from 'sdc-cms-writing'
import { userStore     } from 'sdc-auth-user'

import { publish       } from 'sdc-publish-subscribe'

const typeID = 'R12xnYsdlYtgaylkbYQb3VGASqNwC7r2'

export class TextsStore extends UpdatingStore {

  constructor({
    ...options
  }) {
    super({
      ...options,
      typeID,
    })
    autorun(() => {
      if (this.selected.id) {
        publish('postal-selected', this.selected.values?.target)
      }
    })
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
      } else {
        this.clearEntries()
      }
    })
  }

  @action
  reload = () => {
    this.list({
      params: {
        owner : userStore.user.id,
      }
    })()
    this.typesStore.selected = {hello: 'world'}
  }

  clone = message => action(e => {
    const {id,size,rest,created,...cloned} = {...toJS(message)}
    cloned.values.created = new Date().valueOf()
    this.create(cloned)()
  })

}

export const makeTextsStore = ({backend,...options}) => new TextsStore({...options,api:ContentApi({backend,typeID})})
