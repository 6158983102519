import { requiredParam } from 'sdc-utilities'

const baseUrl = 'customers'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  create : customer => () => backend.post(baseUrl,customer),
  update : customer => () => backend.put(baseUrl,customer),
  remove : customer => () => backend.delete(baseUrl+'/'+customer.id),
  select : customer => () => backend.post(baseUrl+'/'+(customer ? customer.id : null)),
  reload : ()       => () => backend.get(baseUrl+'/selected'),
  list   : ()       => () => backend.get(baseUrl),
})
