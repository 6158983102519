import React from 'react'

import styled from 'styled-components'

const AH = styled.div`
  color: #888;
  background-color: #eee;
  border-color: #ccc;
  font-size: 85%;
  padding: 8px 12px;
  margin-bottom: 8px;
`

export class AlertHelp extends React.Component {
  render() {
    return <AH className="alert" {...this.props}>{this.props.children}</AH>
  }
}
