import React from 'react'

import { Label } from 'sdc-i18n-react'

import { TimeFormatter } from '../../components/datetime/time'

export const Hours = ({businessDays,daysLeft,soll,timeLeft,bookedTime}) => {
  const timeOff = Math.floor(timeLeft / businessDays)
  const bgTime = daysLeft > 0 ? 'transparent' : (timeLeft < 0 ? `rgb(${255+timeOff},255,${255+timeOff})` : `rgb(255,${255-timeOff},${255-timeOff})`)
  return <tr className="text-right">
    <th className="text-center"><Label value="planning.hours" /></th>
    <td className="pr-3"><TimeFormatter inline time={soll} /></td>
    <td className="pr-3" style={{backgroundColor: bgTime}}><TimeFormatter inline time={timeLeft} /></td>
    <td className="pr-3"><TimeFormatter inline time={bookedTime} /></td>
  </tr>
}
