import React from 'react'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { MarkdownPanel } from 'sdc-markdown-viewer'

import { AlertHelp } from '../AlertHelp'

import './InlineHelp.css'


@inject('messagesStore')
@observer
export class InlineHelp extends React.Component {
  constructor(props) {
    super(props)
    this.init(props.opened || false)
    if (this.status.opened) this.props.messagesStore.load(props.category || 'H', props.messageKey, this.update)
  }
  render() {
    if (this.status.opened) {
      return <AlertHelp className="clearfix" style={{fontSize: '14px', lineHeight: '1.2em', marginBottom: '4px'}} ><i
          className="fa fa-minus-square-o pull-right"
          style={{color: '#37f', marginTop: '3px', cursor: 'pointer'}}
          onClick={this.toggle} />
        {this.status.loading && <span className=""><i className="fa fa-spinner fa-spin fa-fw"></i> Hilfe-Text wird geladen...</span>}
        {!this.status.loading && this.status.text && <span className="inline-help"><MarkdownPanel text={this.status.text} /></span>}
      </AlertHelp>
    } else {
      return <i
          className="fa fa-question-circle pull-right"
          style={{color: '#37f', cursor: 'help', fontSize: '12pt'}}
          onClick={this.toggle} />
    }
  }
  @action
  toggle = () => {
    this.status = {
      ...this.status,
      opened : !this.status.opened,
    }
    if (this.status.opened) this.props.messagesStore.load(this.props.category || 'H', this.props.messageKey, this.update)
  }

  @action
  init = opened => {
    this.status = {
      opened,
    }
  }

  @action
  update = status => {
    this.status = {
      ...status,
      opened: this.status.opened,
    }
  }
  @observable status = {}
}
