import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider } from 'mobx-react'

import { DetailsTable    } from '../../components/DetailsTable'
import { editingType     } from 'sdc-cms-writing'
import { ValueTextEdit   } from 'sdc-cms-editing'
import { DetailsDateView } from '../../components/DetailsDateView'

import { CompanySelector } from '../../crm/companies/selector'
import { PostalSelector  } from '../../crm/postal/selector'
import { PartnerSelector } from '../partners/selector'


@inject('editingStore','textsStore','partnersStore','typesStore','companiesStore','postalStore')
@observer
export class TextDetails extends React.Component {
  render() {
    const store = this.props.editingStore
    const types = this.props.typesStore
    const data = {
      dataStore : this.props.textsStore,
      store     : store,
      update    : store.update,
    }
    return (this.props.textsStore.selected.id && editingType.typeID === this.props.textsStore.typeID) ? <Provider {...data}>
      <React.Fragment>
        <hr/>
        <h4>Details zu Text '{this.props.textsStore.selected.values?.name}'</h4>
        <DetailsTable>
          <colgroup>
            <col width="18%" />
            <col width="80%" />
          </colgroup>
          <tbody>
            <tr>
              <td>von</td>
              <td><PostalSelector value={this.props.postalStore.postal(store.selected.values.source)} onChange={store.updateChoice(types.fields.source)} /></td>
            </tr>
            <tr>
              <td>Firma</td>
              <td><CompanySelector value={this.props.companiesStore.company(store.selected.values.contractor)} onChange={store.updateChoice(types.fields.contractor)} /></td>
            </tr>
            <tr>
              <td>an</td>
              <td><PostalSelector value={this.props.postalStore.postal(store.selected.values.target)} onChange={store.updateChoice(types.fields.target)} /></td>
            </tr>
            <tr>
              <td>z.Hd.</td>
              <td><PartnerSelector value={this.props.partnersStore.partner(store.selected.values.to)} onChange={store.updateChoice(types.fields.to)} /></td>
            </tr>
            <DetailsDateView label="Angelegt" field="created" />
          </tbody>
        </DetailsTable>
        <ValueTextEdit field="name"    />
        <ValueTextEdit field="text"    />
        <ValueTextEdit field="anlagen" />
      </React.Fragment>
    </Provider> : null
  }
}
