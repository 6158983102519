import React from 'react'

import Dropzone from 'react-dropzone'


export class LogoUploadPanel extends React.Component {
  render() {
    return <div>
      <h4>{this.props.titel || 'Datei hochladen'}</h4>
      <Dropzone
        onDrop={this.props.onDrop}
        multiple accept="image/*" disablePreview
        style={{width: '100%', height: 'auto', border: 'dashed 2px #9cf', padding: '12px', marginBottom: '12px', borderColor: '#9cf'}}
        activeStyle={{backgroundColor: '#e5eeff', }}
        acceptStyle={{borderColor: '#6c6', }}
        rejectStyle={{borderColor: '#f99', backgroundColor: '#fee', cursor: 'no-drop', }}
      >
        <p style={{margin: '0'}}>{this.props.hint || 'Ziehen Sie eine Datei hierher oder klicken Sie, um eine Datei zum Upload auszuwählen.'}</p>
      </Dropzone>
    </div>
  }
}
