import { requiredParam } from 'sdc-utilities'

const baseUrl = 'messages'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  create : ()          => () => backend.post(baseUrl),
  load   : ({cat,key}) => () => backend.get(baseUrl+'/'+cat+'/'+key),
  remove : message     => () => backend.delete(baseUrl+'/'+message.id),
  update : message     => () => backend.put(baseUrl,message),
  list   : category    => () => {
    if (category === undefined)
      return backend.get(baseUrl)
    else
      return backend.get(baseUrl+'/'+category)
  },
})
