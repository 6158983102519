import React from 'react'

import { observer } from 'mobx-react'

import { Button   } from 'reactstrap'

import { Label    } from 'sdc-i18n-react'

import { appState } from './state'

export const UpdateAvailable = observer(props => appState.updateAvailable ? <span {...props}>
  <Button color="success" outline size="sm" onClick={() => location.reload(true)}>
    <i className="fa fa-fw fa-refresh" />
    <Label value="update.available" padded />
  </Button>
</span> : null)
