import React from 'react'
import { inject, observer } from 'mobx-react'

import { dateCell } from '../../components/columns'

import { CloneButton, CreateButton, ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

import { DataTable } from '../../components/DataTable'


const columns = (holidaysStore) => [{
  Header: <span>
    <ReloadButton title="holidays.reload" onClick={holidaysStore.reload} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      <DeleteButton title="holiday.delete" onClick={holidaysStore.remove(cell.original)} confirm={false} />
    </span>
  )
},{
  Header: 'Name',
  accessor: 'values.name',
  headerClassName: 'left',
},{
  Header: 'Datum',
  accessor: 'values.date',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
},{
  Header: <span>
    <CreateButton title="holiday.create" onClick={holidaysStore.create({})} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      <CloneButton title="holiday.clone" onClick={holidaysStore.clone(cell.original)} />
    </span>
  )
}]

export const HolidayList = inject('holidaysStore')(observer(({holidaysStore}) =>
<DataTable store={holidaysStore} columns={columns(holidaysStore)}
  sorting={[{
    id: 'values.date',
  }]}
/>))
