import React from 'react'
import { inject, observer } from 'mobx-react'

import { Label } from 'sdc-i18n-react'

import { dateCell } from '../../components/columns'

import { ReloadButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'

//import { DataTable } from 'sdc-cms-editing'
import { DataTable } from '../../components/DataTable'


@inject('daysStore')
@observer
export class DaysList extends React.Component {
  render() {
    const {daysStore,...otherProps} = this.props
    return (<DataTable store={daysStore} columns={this.columns(this.props)} {...otherProps}
      sortable={false}
      defaultSorted={[{
        id : 'values.date',
        desc : true,
      }]}
    />)
  }

  columns = ({narrow,creatable,daysStore}) => {
    if (narrow)
      return [nameCol,dateCol]
    else
      return [reloadTrash(daysStore),nameCol,dateCol]
  }

}

const reloadTrash = (daysStore) => ({
  Header: <span>
    <ReloadButton onClick={daysStore.reload} />
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      <DeleteButton onClick={daysStore.remove(cell.original)}/>
    </span>
  )
})
const nameCol = {
  Header: <Label value="field.name" />,
  accessor: 'values.name',
  headerClassName: 'left',
  //Cell : textEdit(customerStore.update),
}
const dateCol = {
  Header: <Label value="field.date" />,
  accessor: 'values.date',
  className: 'centered',
  width: 80,
  resizable: false,
  Cell : dateCell,
}
