import { requiredParam } from 'sdc-utilities'

const baseUrl = 'contracts'

export const api = ({
  backend = requiredParam('backend'),
}) => ({
  create : contract => () => backend.post(baseUrl,contract),
  patch  : id       => () => backend.patch(baseUrl+'/'+id),
  update : contract => () => backend.put(baseUrl,contract),
  remove : contract => () => backend.delete(baseUrl+'/'+contract.id),
  select : contract => () => backend.post(baseUrl+'/'+(contract ? contract.id : null)),
  reload : ()       => () => backend.get(baseUrl+'/selected'),
  list   : ()       => () => backend.get(baseUrl),
})
