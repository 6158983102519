import { observable, action, autorun } from 'mobx'

import moment from 'moment'
import 'moment/locale/de'

import { languagesStore } from 'sdc-i18n-stores'

import { publish        } from 'sdc-publish-subscribe'

import trackingStore from '../../tracking/store'

const asMonth = m => 100*m.year() + m.month() + 1

class MonthStore {
  constructor() {
    autorun(() => {
      if (languagesStore.selectedLanguage) {
        moment.locale(languagesStore.selectedLanguage)
        this.setCurrent(moment(this.selected))
      }
    })
  }
  @observable
  month = {
    num: asMonth(moment()),
  }
  @observable selected = moment().toDate()
  @observable current = moment().format('MMMM YYYY')
  @action prev = () => this.move(-1)
  @action next = () => this.move(+1)
  move = (offset) => {
    const date = moment(this.month.num+'','YYYYMM')
    date.add(offset, 'months')
    publish('month-selected', date)
    this.month.num = asMonth(date)
    this.setCurrent(date)

    const sel = moment(this.selected)
    sel.add(offset, 'months')
    this.selected = sel.toDate()
    trackingStore.setDate(sel.toDate())
  }
  @action selectNum = (num) => {
    this.month.num = num
    const month = moment(num+'','YYYYMM')
    publish('month-selected', month)
    this.selected = month.toDate()
    this.setCurrent(month)
  }
  @action select = (date) => {
    this.selected = date
    const month = moment(date)
    publish('month-selected', month)
    this.month.num = asMonth(month)
    this.setCurrent(month)
  }
  @action setCurrent = (month) => {
    this.current =  month.format('MMMM YYYY')
  }
}

export const makeMonthStore = options => new MonthStore(options)
