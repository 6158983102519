import React from 'react'
import { inject, observer } from 'mobx-react'

import _ from 'lodash'

import { Label } from 'sdc-i18n-react'

import { timeCell } from '../../components/columns'

import { CreateButton } from '../../components/buttons'
import { DeleteButton } from '../../components/delete'
import { PrintButton  } from 'sdc-react-basics'

import { DataTable } from '../../components/DataTable'

import { printBase } from '../../env/constants'


const columns = (creatable,monthStore,workDoneStore,sheetsStore,contractsStore) => [{
  Header: <span>
    {contractsStore.selected.id && <PrintButton title="month.print" href={printBase+contractsStore.selected.id+'/'+monthStore.month.num+'.pdf'} target="_blank" />}
  </span>,
  accessor: 'id',
  headerClassName: 'centered sb-actions',
  className: 'centered sb-actions',
  width: 30,
  sortable: false,
  resizable: false,
  Cell : cell => (
    <span>
      {(!cell.original.total || cell.original.total === 0) && <DeleteButton tag="sheet" title="sheet.delete" onClick={sheetsStore.remove(cell.original)} />}
      {false && cell.original.total > 0 && <PrintButton title="sheet.print" href={printBase+'sheet/'+cell.value+'.pdf'} target="_blank" />}
    </span>
  )
},{
  Header: <Label value="field.ident" />,
  id: 'tag',
  accessor: ({packet={}}) => packet.tag?.length > 8 ? '..'+packet.tag.substring(packet.tag.length-8) : packet.tag,
  headerClassName: 'left',
  width: 80,
},{
  Header: <Label value="field.name" />,
  accessor: 'name',
  headerClassName: 'left',
},{
  Header: <Label value="field.time" />,
  accessor: 'total',
  className: 'right',
  width: 50,
  resizable: false,
  aggregate: values => _.sum(values),
  Cell : timeCell,
},{
  Header: '',
  accessor: 'id',
  headerClassName: 'centered',
  className: 'centered sb-actions',
  width: (creatable ? 30 : 0),
  sortable: false,
  resizable: false,
  show: creatable,
  Cell : cell => (creatable ?
    <span>
      <CreateButton title="workDone.create"
        onClick={workDoneStore.createWorkDone({
          sheetID: cell.original.id,
          month: cell.original.month
        })} />
    </span>
  : null)
}]

@inject('sheetsStore','monthStore','workDoneStore','contractsStore')
@observer
export class SheetsList extends React.Component {
  render() {
    const {packetsStore,monthStore,sheetsStore,contractsStore,creatable,...otherProps} = this.props
    return (this.props.creatable || this.props.sheetsStore.dataList.length > 0) ?
      <DataTable store={this.props.sheetsStore} columns={columns(creatable,monthStore,this.props.workDoneStore,this.props.sheetsStore,this.props.contractsStore)}
      /> : null
  }
}
