import React from 'react'
import { inject, observer } from 'mobx-react'

import { CustomSelect } from '../../components/select'

import './selector.scss'

export const SheetSelector = inject('sheetsStore')(observer(({sheetsStore,...props}) =>
<div onClick={e => e.stopPropagation()}>
  <CustomSelect {...props}
    options={sheetsStore.options}
  />
</div>))
